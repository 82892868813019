import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apps } from "../../utils/api";

// Get Mentor Roadmap
export const getMentorRoadmap = createAsyncThunk('mentor/getMentorRoadmap', async () => {
    try {
        const response = await apps.get(`/api/course/roadmap`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Mentor Roadmap
export const getDetailMentorRoadmap = createAsyncThunk('mentor/getDetailMentorRoadmap', async (id) => {
    try {
        const response = await apps.get(`/api/course/detailRoadmap/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Post Mentor Roadmap
export const postMentorRoadmap = createAsyncThunk('mentor/postMentorRoadmap', async (data) => {
    const payload = JSON.stringify(data)
    try {
        const response = await apps.post(`/api/course/insertRoadmap`, payload);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Update Mentor Roadmap
export const updateMentorRoadmap = createAsyncThunk('mentor/updateMentorRoadmap', async (data) => {
    const payload = JSON.stringify(data)
    try {
        const response = await apps.patch(`/api/course/updateRoadmap/${data.id}`, payload);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Delete Mentor Roadmap
export const deleteMentorRoadmap = createAsyncThunk('mentor/deleteMentorRoadmap', async (id, { dispatch }) => {
    try {
        const response = await apps.delete(`/api/course/deleteRoadmap/${id}`);
        dispatch(getMentorRoadmap());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// GetCoursesByRoadmap
export const getCoursesByRoadmap = createAsyncThunk('mentor/getCoursesByRoadmap', async (id) => {
    try {
        const response = await apps.get(`/api/course/coursesByRoadmap/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Connect Course to Roadmap
export const connectCoursesToRoadmap = createAsyncThunk('mentor/connectCoursesToRoadmap', async (data, { dispatch }) => {

    let payload = {
        "path_id": data.id,
        "courses": [...data.finalCourses]
    };

    try {
        const response = await apps.post(`/api/course/connect`, payload);
        dispatch(getCoursesByRoadmap(data.id));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Disconnect Course From Roadmap
export const disconnectCourseFromRoadmap = createAsyncThunk('mentor/disconnectCourseFromRoadmap', async (data, { dispatch }) => {

    let payload = {
        "path_id": data.pathId,
        "course_id": data.courseId,
        "category_id": data.categoryId
    };

    try {
        const response = await apps.patch(`/api/course/disconnect`, payload);
        dispatch(getCoursesByRoadmap(data.pathId));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Move Up Course
export const moveUpCourse = createAsyncThunk('mentor/moveUpCourse', async (data, { dispatch }) => {
    try {
        const response = await apps.post(`/api/course/moveup_course?path_id=${data.pathId}&object_id=${data.courseId}&position=${data.position}`, null);
        dispatch(getCoursesByRoadmap(data.pathId));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Move Down Course
export const moveDownCourse = createAsyncThunk('mentor/moveDownCourse', async (data, { dispatch }) => {
    try {
        const response = await apps.post(`/api/course/movedown_course?path_id=${data.pathId}&object_id=${data.courseId}&position=${data.position}`, null);
        dispatch(getCoursesByRoadmap(data.pathId));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});


const mentorRoadmapSlice = createSlice({
    name: 'mentorRoadmap',
    initialState: {
        mentorRoadmaps: [],
        coursesByRoadmap: [],
        loadingMentorRoadmaps: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get Mentor Roadmap
            .addCase(getMentorRoadmap.pending, (state) => {
                state.loadingMentorRoadmaps = true;
            })
            .addCase(getMentorRoadmap.fulfilled, (state, action) => {
                state.loadingMentorRoadmaps = false;
                state.mentorRoadmaps = action.payload;
            })
            .addCase(getMentorRoadmap.rejected, (state, action) => {
                state.loadingMentorRoadmaps = false;
                state.error = action.error?.message;
            })

            // Get Detail Mentor Roadmap
            .addCase(getDetailMentorRoadmap.pending, (state) => {
                state.loadingMentorRoadmaps = true;
            })
            .addCase(getDetailMentorRoadmap.fulfilled, (state) => {
                state.loadingMentorRoadmaps = false;
            })
            .addCase(getDetailMentorRoadmap.rejected, (state, action) => {
                state.loadingMentorRoadmaps = false;
                state.error = action.error?.message;
            })

            // Post Mentor Roadmap
            .addCase(postMentorRoadmap.pending, (state) => {
                state.loadingMentorRoadmaps = true;
            })
            .addCase(postMentorRoadmap.fulfilled, (state) => {
                state.loadingMentorRoadmaps = false;
            })
            .addCase(postMentorRoadmap.rejected, (state, action) => {
                state.loadingMentorRoadmaps = false;
                state.error = action.error?.message;
            })

            // Update Mentor Roadmap
            .addCase(updateMentorRoadmap.pending, (state) => {
                state.loadingMentorRoadmaps = true;
            })
            .addCase(updateMentorRoadmap.fulfilled, (state) => {
                state.loadingMentorRoadmaps = false;
            })
            .addCase(updateMentorRoadmap.rejected, (state, action) => {
                state.loadingMentorRoadmaps = false;
                state.error = action.error?.message;
            })

            // Get Courses by Roadmap
            .addCase(getCoursesByRoadmap.pending, (state) => {
                state.loadingMentorRoadmaps = true;
            })
            .addCase(getCoursesByRoadmap.fulfilled, (state, action) => {
                state.loadingMentorRoadmaps = false;
                state.coursesByRoadmap = action.payload;
            })
            .addCase(getCoursesByRoadmap.rejected, (state, action) => {
                state.loadingMentorRoadmaps = false;
                state.error = action.error?.message;
            });
    }
});

export default mentorRoadmapSlice.reducer;
