import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { username } from "../../utils/config";
import { api, apps } from "../../utils/api";

// Get Thread By Mentor
export const getThreadByMentor = createAsyncThunk('mentor/getThreadByMentor', async () => {
    try {
        const response = await apps.get(`/api/forum/thread/mentor?page=1&mentor_id=42921`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Mentor Statistic Statistic
export const getMentorEarningStats = createAsyncThunk('mentor/getMentorEarningStats', async (payload) => {
    try {
        const response = await api.get(`/v1/mentor/earning-chart${payload || ''}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Mentor Statistic Statistic
export const getMentorTransactionStats = createAsyncThunk('mentor/getMentorTransactionStats', async (payload) => {
    try {
        const response = await api.get(`/v1/mentor/transaction-chart${payload || ''}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Mentor Statistic
export const getMentorStatistic = createAsyncThunk('mentor/getMentorStatistic', async () => {
    try {
        const response = await api.get(`/v1/mentor/statistic`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Mentor Statistic Year
export const getMentorStatisticYear = createAsyncThunk('mentor/getMentorStatisticYear', async (year) => {
    try {
        const response = await api.get(`/v1/mentor/statistic-year/${year}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Mentor Transaction
export const getMentorTransaction = createAsyncThunk('mentor/getMentorTransaction', async () => {
    try {
        const response = await api.get(`/v1/mentor/transaction`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Profile Mentor
export const getMentorProfile = createAsyncThunk('mentor/getMentorProfile', async () => {
    try {
        const response = await api.get(`/v1/mentor/${username}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Update Mentor Profile
export const updateMentorProfile = createAsyncThunk('mentor/updateMentorProfile', async (data) => {
    try {
        const response = await api.post(`/v1/mentor/settings`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
});

const mentorSlice = createSlice({
    name: 'mentor',
    initialState: {
        threads: [],
        transactions: [],
        statistic: [],
        statisticYear: [],
        earningStats: [],
        transactionStats: [],
        loadingMentor: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get Mentor Statistic
            .addCase(getMentorStatistic.pending, (state) => {
                state.loadingMentor = true;
            })
            .addCase(getMentorStatistic.fulfilled, (state, action) => {
                state.loadingMentor = false;
                state.statistic = action.payload.result;
            })
            .addCase(getMentorStatistic.rejected, (state, action) => {
                state.loadingMentor = false;
                state.error = action.error?.message;
            })

            // Get Mentor Statistic Year
            .addCase(getMentorStatisticYear.pending, (state) => {
                state.loadingMentor = true;
            })
            .addCase(getMentorStatisticYear.fulfilled, (state, action) => {
                state.loadingMentor = false;
                state.statisticYear = action.payload.result;
            })
            .addCase(getMentorStatisticYear.rejected, (state, action) => {
                state.loadingMentor = false;
                state.error = action.error?.message;
            })

            // Get Mentor Transaction
            .addCase(getMentorTransaction.pending, (state) => {
                state.loadingMentor = true;
            })
            .addCase(getMentorTransaction.fulfilled, (state, action) => {
                state.loadingMentor = false;
                state.transactions = action.payload.result;
            })
            .addCase(getMentorTransaction.rejected, (state, action) => {
                state.loadingMentor = false;
                state.error = action.error?.message;
            })

            // Get Mentor Earnings
            .addCase(getMentorEarningStats.pending, (state) => {
                state.loadingMentor = true;
            })
            .addCase(getMentorEarningStats.fulfilled, (state, action) => {
                state.loadingMentor = false;
                state.earningStats = action.payload.result;
            })
            .addCase(getMentorEarningStats.rejected, (state, action) => {
                state.loadingMentor = false;
                state.error = action.error?.message;
            })

            // Get Mentor Transaction Stats
            .addCase(getMentorTransactionStats.pending, (state) => {
                state.loadingMentor = true;
            })
            .addCase(getMentorTransactionStats.fulfilled, (state, action) => {
                state.loadingMentor = false;
                state.transactionStats = action.payload.result;
            })
            .addCase(getMentorTransactionStats.rejected, (state, action) => {
                state.loadingMentor = false;
                state.error = action.error?.message;
            })

            // Get Threads by Mentor
            .addCase(getThreadByMentor.pending, (state) => {
                state.loadingMentor = true;
            })
            .addCase(getThreadByMentor.fulfilled, (state, action) => {
                state.loadingMentor = false;
                state.threads = action.payload;
            })
            .addCase(getThreadByMentor.rejected, (state, action) => {
                state.loadingMentor = false;
                state.error = action.error?.message;
            })

            // Get Mentor Profile
            .addCase(getMentorProfile.pending, (state) => {
                state.loadingMentor = true;
            })
            .addCase(getMentorProfile.fulfilled, (state) => {
                state.loadingMentor = false;
            })
            .addCase(getMentorProfile.rejected, (state, action) => {
                state.loadingMentor = false;
                state.error = action.error?.message;
            })

            // Update Mentor Profile
            .addCase(updateMentorProfile.pending, (state) => {
                state.loadingMentor = true;
            })
            .addCase(updateMentorProfile.fulfilled, (state) => {
                state.loadingMentor = false;
            })
            .addCase(updateMentorProfile.rejected, (state, action) => {
                state.loadingMentor = false;
                state.error = action.error?.message;
            });
    }
});

export default mentorSlice.reducer;
