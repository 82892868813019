import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apps } from "../../utils/api";

// Get Popular Courses
export const getInteractiveCodingCourses = createAsyncThunk('courses/getInteractiveCodingCourses', async () => {
    try {
        const response = await apps.get(`/api/intcoding/course`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const interactiveCodingCourseSlice = createSlice({
    name: 'interactiveCodingCourse',
    initialState: {
        interactiveCodingCourses: [],
        loadingInteractiveCourse: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getInteractiveCodingCourses.pending, (state) => {
                state.loadingInteractiveCourse = true;
            })
            .addCase(getInteractiveCodingCourses.fulfilled, (state, action) => {
                state.interactiveCodingCourses = action.payload;
                state.loadingInteractiveCourse = false;
            })
            .addCase(getInteractiveCodingCourses.rejected, (state) => {
                state.loadingInteractiveCourse = false;
            });
    }
});

export default interactiveCodingCourseSlice.reducer;
