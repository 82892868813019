import { useEffect, useState } from "react";

const SectionVideo = ({ loading, player, youtubeId, bunnystreamId, diuploadId }) => {
    const [selectedPlayer, setSelectedPlayer] = useState('');

    useEffect(() => {
        setSelectedPlayer(player);
    }, [player]);

    const players = [
        { id: "youtube", src: `https://www.youtube.com/embed/${youtubeId}?modestbranding=1`, condition: youtubeId },
        { id: "bunnystream", src: `https://iframe.mediadelivery.net/embed/${bunnystreamId}?autoplay=false&preload=true`, condition: bunnystreamId },
        { id: "diupload", src: `https://diupload.com/embed/${diuploadId}`, condition: diuploadId },
    ];

    const renderPlayer = () => {
        const activePlayer = players.find((p) => p.id === selectedPlayer && p.condition);
        if (!activePlayer) return null;

        return (
            <iframe
                className="bg-dark w-100"
                src={activePlayer.src}
                title={activePlayer.label}
                sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}
            />
        );
    };

    return (
        <section>
            <div className="ratio ratio-16x9">
                {loading ? (
                    <div className="card bg-dark card-rounded border-0">
                        <div className="card-body d-flex justify-content-center align-items-center">
                            <div className="spinner-border text-primary" style={{ height: '5rem', width: '5rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    renderPlayer()
                )}
            </div>
            <div className="card border-0" hidden={players.filter(({ condition }) => condition).length < 2}>
                <div className="card-body d-flex gap-2">
                    {players
                        .filter(({ condition }) => condition)
                        .map(({ id }, index) => (
                            <button
                                key={id}
                                onClick={() => setSelectedPlayer(id)}
                                className={`btn ${selectedPlayer === id ? "btn-primary" : "btn-outline-primary"} btn-sm rounded-pill`}
                            >
                                Server {index + 1}
                            </button>
                        ))}
                </div>
            </div>
        </section>
    );
};

export default SectionVideo;
