import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apps } from "../../utils/api";

export const getLogsCertificates = createAsyncThunk('user/getLogsCertificates', async () => {
    try {
        const response = await apps.get(`/api/certificate/logs`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const certificatesSlice = createSlice({
    name: 'certificates',
    initialState: {
        certificates: [],
        loadingCertificates: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLogsCertificates.pending, (state) => {
                state.loading = true;
            })
            .addCase(getLogsCertificates.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload.status !== "failed") {
                    state.certificates = action.payload.result;
                }
            })
            .addCase(getLogsCertificates.rejected, (state) => {
                state.loading = false;
            });
    }
});

export default certificatesSlice.reducer;
