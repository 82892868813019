import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apps } from "../../utils/api";

export const getNotifications = createAsyncThunk('notifications/getNotifications', async () => {
    try {
        const response = await apps.get(`/api/notification`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data.result;
    } catch (error) {
        throw new Error(error);
    }
});

const notificationSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: [],
        loadingNotifications: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Notifications
            .addCase(getNotifications.pending, (state, action) => {
                state.loadingNotifications = true;
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.loadingNotifications = false;
                state.notifications = action.payload;
            })
            .addCase(getNotifications.rejected, (state, action) => {
                state.loadingNotifications = false;
            })
    },
});

export default notificationSlice.reducer;
