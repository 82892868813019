import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userToken, username } from "../../utils/config";
import { apps, publicApps } from "../../utils/api";

export const getMyUser = createAsyncThunk('user/getMyUser', async () => {
    try {
        const response = await apps.get(`/api/user/profile/detail/${username}`);
        if (response?.data.available_for_work) {
            localStorage.setItem('availableForWork', response?.data.available_for_work);
        }
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || "Failed to fetch user data");
    };
});

export const getUserByUsername = createAsyncThunk('user/getUserByUsername', async ({ username }) => {
    try {
        const appsInstance = userToken ? apps : publicApps;
        const response = await appsInstance.get(`/api/user/profile/detail/${username}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || "Failed to fetch user data");
    };
});

export const updateAvatarProfile = createAsyncThunk('user/updateAvatarProfile', async (avatar, { dispatch }) => {
    let data = {
        "profile_picture": avatar
    };

    try {
        const response = await apps.post(`/api/user/profile/updateProfilePicture`, JSON.stringify(data));
        dispatch(getMyUser());
        dispatch(getUserByUsername(username))
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
        myUser: [],
        user: [],
        workStatusResult: {},
        loadingUser: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMyUser.pending, (state) => {
                state.loadingUser = true;
            })
            .addCase(getMyUser.fulfilled, (state, action) => {
                state.loadingUser = false;
                state.myUser = action.payload;
            })
            .addCase(getMyUser.rejected, (state) => {
                state.loadingUser = false;
            })
            .addCase(getUserByUsername.pending, (state) => {
                state.loadingUser = true;
            })
            .addCase(getUserByUsername.fulfilled, (state, action) => {
                state.loadingUser = false;
                state.user = action.payload;
            })
            .addCase(getUserByUsername.rejected, (state) => {
                state.loadingUser = false;
            });
    }
});

export default userSlice.reducer;
