import { configureStore } from '@reduxjs/toolkit';
import UserReducer from '../features/user/userSlice';
import CertificatesReducer from '../features/user/certificatesSlice';
import EducationsReducer from '../features/user/educationsSlice';
import NotificationsReducer from '../features/notifications/notificationSlice';
import LeaderboardReducer from '../features/user/leaderboardSlice';
import CoursesReducer from '../features/courses/courseSlice';
import CompletedCourseReducer from '../features/courses/courseCheckSlice';
import PortfoliosReducer from '../features/user/portfoliosSlice';
import OccupationReducer from '../features/user/occupationSlice';
import SkillsReducer from '../features/user/skillSlice';
import FeedbackReducer from '../features/feedback/feedbackSlice';
import LessonReducer from '../features/lessons/lessonSlice';
import NoteReducer from '../features/lessons/noteSlice';
import DiscussionReducer from '../features/discussion/discussionSlice';
import EventReducer from '../features/events/eventSlice';
import TutorialReducer from '../features/tutorials/tutorialSlice';
import RoadmapReducer from '../features/roadmaps/roadmapSlice';
import InteractiveCodingReducer from '../features/courses/interactiveCodingSlice';
import LearnReducer from '../features/learn/learnSlice';
import BannerReducer from '../features/banner/bannerSlice';
import MentorCourseReducer from '../features/mentor/mentorCourseSlice';
import MentorReducer from '../features/mentor/mentorSlice';
import MentorProductReducer from '../features/mentor/mentorProductSlice';
import MentorRoadmapReducer from '../features/mentor/mentorRoadmapSlice';
import QuizReducer from '../features/quiz/quizSlice';
import ThemeReducer from '../features/theme/themeSlice';
import KelasFullstackReducer from '../features/submissionKelasfullstack/submissionSlice';
import PaymentReducer from '../features/payment/paymentSlice';
import BrowseCourseReducer from '../features/browseCourse/browseCourseSlice';
import FastTrackReducer from '../features/fasttrack/fasttrackSlice';
import AuthReducer from '../features/auth/authSlice';

const isProduction = process.env.NODE_ENV === 'production';

const store = configureStore({
        reducer: {
                auth: AuthReducer,
                user: UserReducer,
                certificates: CertificatesReducer,
                notifications: NotificationsReducer,
                leaderboards: LeaderboardReducer,

                // Banner
                banners: BannerReducer,

                // Courses
                courses: CoursesReducer,
                completedCourse: CompletedCourseReducer,
                interactiveCodingCourse: InteractiveCodingReducer,

                // Lesson
                lesson: LessonReducer,
                note: NoteReducer,

                // Discussion
                discussion: DiscussionReducer,

                // Feedback
                feedbacks: FeedbackReducer,

                // Education
                education: EducationsReducer,

                // Portfolio
                portfolio: PortfoliosReducer,

                // Occupation
                occupation: OccupationReducer,

                // Skill
                skill: SkillsReducer,

                // Events
                events: EventReducer,

                // Tutorial
                tutorials: TutorialReducer,

                // Roadmap
                roadmap: RoadmapReducer,

                // Learn
                learn: LearnReducer,

                // Mentor Course
                mentorCourse: MentorCourseReducer,
                mentorProduct: MentorProductReducer,

                // Mentor Roadmap
                mentorRoadmap: MentorRoadmapReducer,

                // Mentor
                mentor: MentorReducer,

                // Quiz/Exercise
                quiz: QuizReducer,

                //Theme
                theme: ThemeReducer,

                // Kelas Fullstack Submission
                kelasFullstackSubmission: KelasFullstackReducer,

                // Payment
                payment: PaymentReducer,

                // Browse Course
                browseCourse: BrowseCourseReducer,

                // Fast Track
                fastTrack: FastTrackReducer
        },
},
        // Disable devTools in production
        isProduction ? undefined : window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
