import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apps } from "../../utils/api";

// Get All Notes
export const getAllNotes = createAsyncThunk('lesson/getAllNotes', async () => {
    try {
        const response = await apps.get(`/api/dashboard/notes/get_all`, {
            headers: {
                "Content-type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Notes by Lesson
export const getNotes = createAsyncThunk('lesson/getNotes', async (lessonId) => {
    try {
        const response = await apps.get(`/api/entry/index/notes?filter[lesson_id]=${lessonId}`, {
            headers: {
                "Content-type": "application/json",
            }
        });
        return response.data.results;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Single Note
export const getNote = createAsyncThunk('lesson/geNote', async (id) => {
    try {
        const response = await apps.get(`/api/entry/detail/notes/${id}`);
        return response.data.result;
    } catch (error) {
        throw new Error(error);
    }
});

export const postNote = createAsyncThunk('lesson/postNote', async ({ lessonId, noteMinute, noteTitle, noteDescription }, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('lesson_id', lessonId);
        data.append('minute', noteMinute);
        data.append('title', noteTitle);
        data.append('description', noteDescription);

        const response = await apps.post(`/api/entry/insert/notes`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch(getNotes(lessonId));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const updateNote = createAsyncThunk('lesson/updateNote', async ({ id, lessonId, noteMinute, noteTitle, noteDescription }, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('lesson_id', lessonId);
        data.append('minute', noteMinute);
        data.append('title', noteTitle);
        data.append('description', noteDescription);

        const response = await apps.post(`/api/entry/update/notes/${id}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch(getNotes(lessonId));
        return response.data;

    } catch (error) {
        throw new Error(error);
    }
});

export const deleteNote = createAsyncThunk('lesson/deleteNote', async ({ id, lessonId }, { dispatch }) => {
    try {
        const response = await apps.delete(`/api/entry/delete/notes/${id}`);
        dispatch(getNotes(lessonId));
        dispatch(getAllNotes());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    allNotes: [],
    notes: [],
    note: {},
    postNoteResult: {},
    updateNoteResult: {},
    deleteNoteResult: {},
    loadingNotes: false
};

const noteSlice = createSlice({
    name: 'notes',
    initialState,
    reducers: {
        resetNoteState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            // Get All Notes
            .addCase(getAllNotes.pending, (state) => {
                state.loadingNotes = true;
            })
            .addCase(getAllNotes.fulfilled, (state, action) => {
                state.loadingNotes = false;
                state.allNotes = action.payload || [];
            })
            .addCase(getAllNotes.rejected, (state, action) => {
                state.loadingNotes = false;
                state.error = action.error?.message; // Menyimpan error jika ada
            })

            // Get Notes
            .addCase(getNotes.pending, (state) => {
                state.loadingNotes = true;
            })
            .addCase(getNotes.fulfilled, (state, action) => {
                state.loadingNotes = false;
                state.notes = action.payload;
            })
            .addCase(getNotes.rejected, (state, action) => {
                state.loadingNotes = false;
                state.error = action.error?.message;
            })

            // Get Note
            .addCase(getNote.pending, (state) => {
                state.loadingNotes = true;
            })
            .addCase(getNote.fulfilled, (state, action) => {
                state.loadingNotes = false;
                state.note = action.payload;
            })
            .addCase(getNote.rejected, (state, action) => {
                state.loadingNotes = false;
                state.error = action.error?.message;
            })

            // Post Note
            .addCase(postNote.pending, (state) => {
                state.loadingNotes = true;
            })
            .addCase(postNote.fulfilled, (state, action) => {
                state.loadingNotes = false;
                state.postNoteResult = action.payload;
            })
            .addCase(postNote.rejected, (state, action) => {
                state.loadingNotes = false;
                state.error = action.error?.message;
            })

            // Update Note
            .addCase(updateNote.pending, (state) => {
                state.loadingNotes = true;
            })
            .addCase(updateNote.fulfilled, (state, action) => {
                state.loadingNotes = false;
                state.updateNoteResult = action.payload;
            })
            .addCase(updateNote.rejected, (state, action) => {
                state.loadingNotes = false;
                state.error = action.error?.message;
            })

            // Delete Note
            .addCase(deleteNote.pending, (state) => {
                state.loadingNotes = true;
            })
            .addCase(deleteNote.fulfilled, (state, action) => {
                state.loadingNotes = false;
                state.deleteNoteResult = action.payload;
            })
            .addCase(deleteNote.rejected, (state, action) => {
                state.loadingNotes = false;
                state.error = action.error?.message;
            });
    }
});

export const { resetNoteState } = noteSlice.actions;
export default noteSlice.reducer;
