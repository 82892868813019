import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apps } from "../../utils/api";

// Get Course by slug
export const getFeedbacksByCourse = createAsyncThunk('feedback/getFeedbacksByCourse', async (slug) => {
    try {
        const response = await apps.get(`/api/feedback/course/${slug}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: {
        courseFeedbacks: [],
        loadingFeedback: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFeedbacksByCourse.pending, (state) => {
                state.loadingFeedback = true;
            })
            .addCase(getFeedbacksByCourse.fulfilled, (state, action) => {
                state.loadingFeedback = false;
                state.courseFeedbacks = action.payload;
            })
            .addCase(getFeedbacksByCourse.rejected, (state) => {
                state.loadingFeedback = false;
            })
    }
});

export default feedbackSlice.reducer;
