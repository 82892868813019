import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

// Get roadmaps
export const getFeaturedRoadmaps = createAsyncThunk('roadmaps/getFeaturedRoadmaps', async () => {
    try {
        const response = await api.get(`/v1/roadmap/featured?limit=200&page=1`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get roadmaps
export const getMyRoadmaps = createAsyncThunk('roadmaps/getMyRoadmaps', async () => {
    try {
        const response = await api.get(`/path/user`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get roadmap by slug
export const getRoadmap = createAsyncThunk('roadmaps/getRoadmap', async (slug) => {
    try {
        const response = await api.get(`/v1/path/${slug}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const roadmapSlice = createSlice({
    name: 'roadmap',
    initialState: {
        featuredRoadmaps: [],
        myRoadmaps: [],
        roadmap: [],
        loadingRoadmap: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get Featured Roadmaps
            .addCase(getFeaturedRoadmaps.pending, (state) => {
                state.loadingRoadmap = true;
            })
            .addCase(getFeaturedRoadmaps.fulfilled, (state, action) => {
                state.loadingRoadmap = false;
                state.featuredRoadmaps = action.payload;
            })
            .addCase(getFeaturedRoadmaps.rejected, (state, action) => {
                state.loadingRoadmap = false;
                state.error = action.error?.message;
            })

            // Get My Roadmaps
            .addCase(getMyRoadmaps.pending, (state) => {
                state.loadingRoadmap = true;
            })
            .addCase(getMyRoadmaps.fulfilled, (state, action) => {
                state.loadingRoadmap = false;
                if (action.payload.error !== "No Content") {
                    state.myRoadmaps = action.payload;
                }
            })
            .addCase(getMyRoadmaps.rejected, (state, action) => {
                state.loadingRoadmap = false;
                state.error = action.error?.message;
            })

            // Get Roadmap Detail
            .addCase(getRoadmap.pending, (state) => {
                state.loadingRoadmap = true;
            })
            .addCase(getRoadmap.fulfilled, (state, action) => {
                state.loadingRoadmap = false;
                state.roadmap = action.payload;
            })
            .addCase(getRoadmap.rejected, (state, action) => {
                state.loadingRoadmap = false;
                state.error = action.error?.message;
            });
    }
});

export default roadmapSlice.reducer;
