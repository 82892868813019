import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apps } from "../../utils/api";

export const getPortfolios = createAsyncThunk('user/getPortfolios', async () => {
    try {
        const response = await apps.get(`/api/entry/index/user_portfolio`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    };
});

export const getPortfolio = createAsyncThunk('user/getPortfolio', async (id, { dispatch }) => {
    try {
        const response = await apps.get(`/api/entry/detail/user_portfolio/${id}`);
        dispatch(getPortfolios());
        return response.data.result;
    } catch (error) {
        throw new Error(error);
    }
});

export const postPortfolio = createAsyncThunk('user/postPortfolio', async ({ payload, screenshot }, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('project_name', payload.project_name);
        data.append('role_name', payload.role_name);
        data.append('year', payload.year);
        data.append('description', payload.description);
        data.append('thumbnail', screenshot);
        data.append('attachment', payload.attachment);

        const response = await apps.post(`/api/entry/insert/user_portfolio`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch(getPortfolios());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const updatePortfolio = createAsyncThunk('user/updatePortfolio', async ({ payload, screenshot }, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('project_name', payload.project_name);
        data.append('role_name', payload.role_name);
        data.append('year', payload.year);
        data.append('description', payload.description);
        data.append('thumbnail', screenshot);
        data.append('attachment', payload.attachment);

        const response = await apps.post(`/api/entry/update/user_portfolio/${payload.id}`, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        dispatch(getPortfolios());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const deletePortfolio = createAsyncThunk('user/deletePortfolio', async (id, { dispatch }) => {
    try {
        const response = await apps.delete(`/api/entry/delete/user_portfolio/${id}`, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        dispatch(getPortfolios());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    portfolios: [],
    portfolio: {},
    loadingPortfolio: false
};

const portfolioSlice = createSlice({
    name: 'portfolio',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get Portfolios Array
            .addCase(getPortfolios.pending, (state) => {
                state.loadingPortfolio = true;
            })
            .addCase(getPortfolios.fulfilled, (state, action) => {
                state.loadingPortfolio = false;
                if (action.payload.status !== 'failed') {
                    state.portfolios = action.payload.results;
                } else {
                    state.portfolios = [];
                }
            })
            .addCase(getPortfolios.rejected, (state) => {
                state.loadingPortfolio = false;
            })

            // Get Single Portfolio
            .addCase(getPortfolio.pending, (state) => {
                state.loadingPortfolio = true;
            })
            .addCase(getPortfolio.fulfilled, (state, action) => {
                state.loadingPortfolio = false;
                state.portfolio = action.payload;
            })
            .addCase(getPortfolio.rejected, (state) => {
                state.loadingPortfolio = false;
            })

            // Post Portfolio
            .addCase(postPortfolio.pending, (state) => {
                state.loadingPortfolio = true;
            })
            .addCase(postPortfolio.fulfilled, (state) => {
                state.loadingPortfolio = false;
            })
            .addCase(postPortfolio.rejected, (state) => {
                state.loadingPortfolio = false;
            })

            // Update Portfolio
            .addCase(updatePortfolio.pending, (state) => {
                state.loadingPortfolio = true;
            })
            .addCase(updatePortfolio.fulfilled, (state) => {
                state.loadingPortfolio = false;
            })
            .addCase(updatePortfolio.rejected, (state) => {
                state.loadingPortfolio = false;
            })

            // Delete Portfolio
            .addCase(deletePortfolio.pending, (state) => {
                state.loadingPortfolio = true;
            })
            .addCase(deletePortfolio.fulfilled, (state) => {
                state.loadingPortfolio = false;
            })
            .addCase(deletePortfolio.rejected, (state) => {
                state.loadingPortfolio = false;
            });
    }
});

export default portfolioSlice.reducer;