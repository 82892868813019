import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apps } from "../../utils/api";

export const getEducations = createAsyncThunk('user/getEducations', async () => {
    try {
        const response = await apps.get(`/api/entry/index/user_education`);
        return response.data.results;
    } catch (error) {
        throw new Error(error);
    }
});

export const getEducation = createAsyncThunk('user/getEducation', async (id, { dispatch }) => {
    try {
        const response = await apps.get(`/api/entry/detail/user_education/${id}`);
        dispatch(getEducations());
        return response.data.result;

    } catch (error) {
        throw new Error(error);
    };
});

export const postEducation = createAsyncThunk('user/postEducation', async (payload, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('level', payload.level);
        data.append('ipk', payload.ipk);
        data.append('major', payload.major);
        data.append('institution', payload.institution);
        data.append('category', payload.category);
        data.append('description', payload.description);
        data.append('status', 'publish');
        data.append('graduated_at', payload.graduated_at);

        const response = await apps.post(`/api/entry/insert/user_education`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch(getEducations());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const updateEducation = createAsyncThunk('user/updateEducation', async (payload, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('level', payload.level);
        data.append('ipk', payload.ipk);
        data.append('major', payload.major);
        data.append('institution', payload.institution);
        data.append('category', payload.category);
        data.append('description', payload.description);
        data.append('status', 'publish');
        data.append('graduated_at', payload.graduated_at);

        const response = await apps.post(`/api/entry/update/user_education/${payload.id}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch(getEducations());
        return response.data;

    } catch (error) {
        throw new Error(error);
    }
});

export const deleteEducation = createAsyncThunk('user/deleteEducation', async (id, { dispatch }) => {
    try {
        const response = await apps.delete(`/api/entry/delete/user_education/${id}`, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch(getEducations());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    educations: [],
    education: {},
    loadingEducation: false
};

const educationsSlice = createSlice({
    name: 'educations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get Educations Array
            .addCase(getEducations.pending, (state) => {
                state.loadingEducation = true;
            })
            .addCase(getEducations.fulfilled, (state, action) => {
                state.loadingEducation = false;
                state.educations = action.payload;
            })
            .addCase(getEducations.rejected, (state) => {
                state.loadingEducation = false;
            })

            // Get Single Education
            .addCase(getEducation.pending, (state) => {
                state.loadingEducation = true;
            })
            .addCase(getEducation.fulfilled, (state, action) => {
                state.loadingEducation = false;
                state.education = action.payload;
            })
            .addCase(getEducation.rejected, (state) => {
                state.loadingEducation = false;
            })

            // Post Education
            .addCase(postEducation.pending, (state) => {
                state.loadingEducation = true;
            })
            .addCase(postEducation.fulfilled, (state) => {
                state.loadingEducation = false;
            })
            .addCase(postEducation.rejected, (state) => {
                state.loadingEducation = false;
            })

            // Update Education
            .addCase(updateEducation.pending, (state) => {
                state.loadingEducation = true;
            })
            .addCase(updateEducation.fulfilled, (state) => {
                state.loadingEducation = false;
            })
            .addCase(updateEducation.rejected, (state) => {
                state.loadingEducation = false;
            })

            // Delete Education
            .addCase(deleteEducation.pending, (state) => {
                state.loadingEducation = true;
            })
            .addCase(deleteEducation.fulfilled, (state) => {
                state.loadingEducation = false;
            })
            .addCase(deleteEducation.rejected, (state) => {
                state.loadingEducation = false;
            });
    }
});

export default educationsSlice.reducer;
