import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

// Post Answers
export const postAnswers = createAsyncThunk('fasttrack/postAnswers', async ({ userId, responses }) => {
    try {
        let data = {
            "source": "kelasfullstack",
            "user_id": userId,
            "responses": JSON.stringify(responses)
        };

        const response = await api.post(`/user-reference`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;

    } catch (error) {
        throw new Error(error);
    };
});

const fasttrackSlice = createSlice({
    name: 'fasttrack',
    initialState: {
        loadingFasttrack: false
    },
    extraReducers: (builder) => {
        builder
            .addCase(postAnswers.pending, (state, action) => {
                state.loadingFasttrack = true;
            })
            .addCase(postAnswers.fulfilled, (state, action) => {
                state.loadingFasttrack = false;
            })
            .addCase(postAnswers.rejected, (state, action) => {
                state.loadingFasttrack = false;
            });
    },
});

export default fasttrackSlice.reducer;
