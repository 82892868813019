import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api, apps } from "../../utils/api";

// Get All Quizzes
export const getAllQuizzes = createAsyncThunk('quiz/getAllQuizzes', async () => {
    try {
        const response = await api.get(`/quiz?category=Exam`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Quizzes by tag
export const getQuizzesByTag = createAsyncThunk('quiz/getQuizzesByTag', async (tag) => {
    try {
        const response = await api.get(`/quiz?category=Exam&label=${tag}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Quizzes by group
export const getQuizzesByGroup = createAsyncThunk('quiz/questions/', async (slug) => {
    try {
        const response = await api.get(`/quiz/questions/${slug}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get latest Quiz by User
export const getLatestQuiz = createAsyncThunk('quiz/getLatestQuiz', async () => {
    try {
        const response = await api.get(`/quiz/latest`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Recent Quiz by User
export const getRecentQuizzes = createAsyncThunk('quiz/getRecentQuizzes', async () => {
    try {
        const response = await api.get(`/quiz/recent`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get My Quizzes
export const getMyQuizzes = createAsyncThunk('quiz/getMyQuizzes', async () => {
    try {
        const response = await api.get(`/quiz/user?category=Exam`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Quiz
export const getQuiz = createAsyncThunk('quiz/getQuiz', async (slug) => {
    try {
        const response = await api.get(`/quiz/detail/${slug}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Quiz History
export const getQuizHistories = createAsyncThunk('quiz/getQuizHistories', async (groupId) => {
    try {
        const response = await api.get(`/quiz/history/${groupId}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Post Answers
export const postAnswers = createAsyncThunk('quiz/postAnswers', async ({ groupId, duration, sessionId, answer }) => {
    try {
        let data = {
            "group_id": groupId,
            "duration": duration,
            "session_id": sessionId,
            "answer": answer
        };
        const response = await api.post(`/quiz/submit`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Post marking understand
export const postUnderstand = createAsyncThunk('quiz/postUnderstand', async ({ courseId, lessonId }) => {
    try {
        let data = {
            "course_id": courseId,
            "lesson_id": lessonId
        };

        const response = await apps.post(`/api/quiz/understand`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get My Certificate
export const getMyCertificate = createAsyncThunk('/api/certificate/claim/', async (id) => {
    try {
        let data = { object_id: id, object_type: 'exercise' }
        const response = await apps.post(`/api/certificate/claim/`, JSON.stringify(data));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Result info by group
export const getResultInfoByGroup = createAsyncThunk('/quiz/result', async (groupId) => {
    try {
        const response = await api.get(`/quiz/result?group_id=${groupId}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});


const quizzesSlice = createSlice({
    name: 'quiz',
    initialState: {
        quizzes: [],
        quizzesByTag: [],
        quizzesByGroup: [],
        latestQuiz: {},
        myQuizzes: [],
        quizHistories: [],
        recentQuizzes: [],
        quiz: {},
        resultInfo: {},
        loadingQuiz: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get All Quizzes
            .addCase(getAllQuizzes.pending, (state) => {
                state.loadingQuiz = true;
            })
            .addCase(getAllQuizzes.fulfilled, (state, action) => {
                state.loadingQuiz = false;
                state.quizzes = action.payload;
            })
            .addCase(getAllQuizzes.rejected, (state, action) => {
                state.loadingQuiz = false;
                state.error = action.error?.message;
            })

            // Get Quizzes By Tag
            .addCase(getQuizzesByTag.pending, (state) => {
                state.loadingQuiz = true;
            })
            .addCase(getQuizzesByTag.fulfilled, (state, action) => {
                state.loadingQuiz = false;
                state.quizzesByTag = action.payload.error ? [] : action.payload;
            })
            .addCase(getQuizzesByTag.rejected, (state, action) => {
                state.loadingQuiz = false;
                state.error = action.error?.message;
            })

            // Get Quizzes By Group
            .addCase(getQuizzesByGroup.pending, (state) => {
                state.loadingQuiz = true;
            })
            .addCase(getQuizzesByGroup.fulfilled, (state, action) => {
                state.loadingQuiz = false;
                state.quizzesByGroup = action.payload;
            })
            .addCase(getQuizzesByGroup.rejected, (state, action) => {
                state.loadingQuiz = false;
                state.error = action.error?.message;
            })

            // Get Detail Quiz By Slug
            .addCase(getQuiz.pending, (state) => {
                state.loadingQuiz = true;
            })
            .addCase(getQuiz.fulfilled, (state, action) => {
                state.loadingQuiz = false;
                state.quiz = action.payload;
            })
            .addCase(getQuiz.rejected, (state, action) => {
                state.loadingQuiz = false;
                state.error = action.error?.message;
            })

            // Get Latest Quiz
            .addCase(getLatestQuiz.pending, (state) => {
                state.loadingQuiz = true;
            })
            .addCase(getLatestQuiz.fulfilled, (state, action) => {
                state.loadingQuiz = false;
                state.latestQuiz = action.payload.error ? {} : action.payload;
            })
            .addCase(getLatestQuiz.rejected, (state, action) => {
                state.loadingQuiz = false;
                state.error = action.error?.message;
            })

            // Get Recent Quizzes
            .addCase(getRecentQuizzes.pending, (state) => {
                state.loadingQuiz = true;
            })
            .addCase(getRecentQuizzes.fulfilled, (state, action) => {
                state.loadingQuiz = false;
                state.recentQuizzes = action.payload;
            })
            .addCase(getRecentQuizzes.rejected, (state, action) => {
                state.loadingQuiz = false;
                state.error = action.error?.message;
            })

            // Get My Quizzes
            .addCase(getMyQuizzes.pending, (state) => {
                state.loadingQuiz = true;
            })
            .addCase(getMyQuizzes.fulfilled, (state, action) => {
                state.loadingQuiz = false;
                state.myQuizzes = action.payload.error ? {} : action.payload;
            })
            .addCase(getMyQuizzes.rejected, (state, action) => {
                state.loadingQuiz = false;
                state.error = action.error?.message;
            })

            // Get Quiz Histories
            .addCase(getQuizHistories.pending, (state) => {
                state.loadingQuiz = true;
            })
            .addCase(getQuizHistories.fulfilled, (state, action) => {
                state.loadingQuiz = false;
                state.quizHistories = action.payload.error ? {} : action.payload;
            })
            .addCase(getQuizHistories.rejected, (state, action) => {
                state.loadingQuiz = false;
                state.error = action.error?.message;
            })

            // Get My Result Info
            .addCase(getResultInfoByGroup.pending, (state) => {
                state.loadingQuiz = true;
            })
            .addCase(getResultInfoByGroup.fulfilled, (state, action) => {
                state.loadingQuiz = false;
                state.resultInfo = action.payload.error ? {} : action.payload;
            })
            .addCase(getResultInfoByGroup.rejected, (state, action) => {
                state.loadingQuiz = false;
                state.error = action.error?.message;
            });
    }
});

export default quizzesSlice.reducer;
