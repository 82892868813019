import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

// Get Popular Courses
export const getPopularCourses = createAsyncThunk('courses/getPopularCourses', async () => {
    try {
        const response = await api.get(`/course/popular`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Free Courses
export const getFreeCourses = createAsyncThunk('courses/getFreeCourses', async () => {
    try {
        const response = await api.get(`/course?limit=200&course_label=free&page=1`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get My Courses
export const getMyCourses = createAsyncThunk('courses/getMyCourses', async (page) => {
    try {
        const endpoints = ['paid', 'free'];
        const requests = endpoints.map(mode => api.get(`/lesson/progress/user?mode=${mode}&page=${page}&limit=50`));

        const responses = await Promise.all(requests);
        let myCourses = [];

        responses.forEach(response => {
            if (!response.data.error) {
                myCourses.push(...response.data);
            }
        });

        // Remove duplicate courses
        const filteredCourses = Array.from(new Set(myCourses.map(course => course.id)))
            .map(id => myCourses.find(course => course.id === id));

        return filteredCourses;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Courses by tag
export const getCoursesByTag = createAsyncThunk('courses/getCoursesByTag', async (tag) => {
    try {
        const response = await api.get(`/course/tag/${tag}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Course by slug
export const getCourse = createAsyncThunk('courses/getCourse', async (slug) => {
    try {
        const response = await api.get(`/course/detailComprehensifByUser/${slug}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const courseSlice = createSlice({
    name: 'course',
    initialState: {
        myCourses: [],
        popularCourses: [],
        freeCourses: [],
        coursesByTag: [],
        course: {},
        loadingCourse: false,
        coursePage: 1
    },
    reducers: {
        incrementCoursePage: (state) => {
            state.coursePage += 1;
        }
    },
    extraReducers: (builder) => {
        builder
            // Get Popular Courses
            .addCase(getPopularCourses.pending, (state) => {
                state.loadingCourse = true;
            })
            .addCase(getPopularCourses.fulfilled, (state, action) => {
                state.loadingCourse = false;
                if (action.payload.error !== 'No Content') {
                    state.popularCourses = action.payload;
                }
            })
            .addCase(getPopularCourses.rejected, (state) => {
                state.loadingCourse = false;
            })

            // Get Free Courses
            .addCase(getFreeCourses.pending, (state) => {
                state.loadingCourse = true;
            })
            .addCase(getFreeCourses.fulfilled, (state, action) => {
                state.loadingCourse = false;
                state.freeCourses = action.payload;
            })
            .addCase(getFreeCourses.rejected, (state) => {
                state.loadingCourse = false;
            })

            // Get My Courses
            .addCase(getMyCourses.pending, (state) => {
                state.loadingCourse = true;
            })
            .addCase(getMyCourses.fulfilled, (state, action) => {
                state.loadingCourse = false;
                if (action.payload.error !== 'No Content') {
                    state.myCourses = action.payload;
                }
            })
            .addCase(getMyCourses.rejected, (state) => {
                state.loadingCourse = false;
            })

            // Get Courses by Tag
            .addCase(getCoursesByTag.pending, (state) => {
                state.loadingCourse = true;
            })
            .addCase(getCoursesByTag.fulfilled, (state, action) => {
                state.loadingCourse = false;
                state.coursesByTag = action.payload;
            })
            .addCase(getCoursesByTag.rejected, (state) => {
                state.loadingCourse = false;
            })

            // Get Single Course
            .addCase(getCourse.pending, (state) => {
                state.loadingCourse = true;
            })
            .addCase(getCourse.fulfilled, (state, action) => {
                state.loadingCourse = false;
                state.course = action.payload;
            })
            .addCase(getCourse.rejected, (state) => {
                state.loadingCourse = false;
            });
    }
});

export const { incrementCoursePage } = courseSlice.actions;
export default courseSlice.reducer;
