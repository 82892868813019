import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

export const getLatestPayments = createAsyncThunk('payment/getLatestPayments', async () => {
    try {
        const response = await api.get(`/v1/payment/latest`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const getPaymentOrders = createAsyncThunk('payment/getPaymentOrders', async () => {
    try {
        const response = await api.get(`/v1/payment/orders?limit=1000`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data.results;
    } catch (error) {
        throw new Error(error);
    }
});

const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        latestPayments: [],
        paymentOrders: [],
        loadingPayment: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get Latest Payments
            .addCase(getLatestPayments.pending, (state) => {
                state.loadingPayment = true;
            })
            .addCase(getLatestPayments.fulfilled, (state, action) => {
                state.loadingPayment = false;
                state.latestPayments = action.payload;
            })
            .addCase(getLatestPayments.rejected, (state, action) => {
                state.loadingPayment = false;
                state.error = action.error?.message;
            })

            // Get Payment Orders
            .addCase(getPaymentOrders.pending, (state) => {
                state.loadingPayment = true;
            })
            .addCase(getPaymentOrders.fulfilled, (state, action) => {
                state.loadingPayment = false;
                state.paymentOrders = action.payload;
            })
            .addCase(getPaymentOrders.rejected, (state, action) => {
                state.loadingPayment = false;
                state.error = action.error?.message;
            });
    }
});

export default paymentSlice.reducer;
