import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        isLoggedOut: false,
        errorMessage: null
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
            state.isLoggedOut = false;
        },
        logout: (state, action) => {
            state.user = null;
            state.isLoggedOut = true;
            state.errorMessage = action.payload;
        },
    },
});

export const { setUser, logout } = authSlice.actions;
export default authSlice.reducer;
