import { createSlice, createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

// Get Course by slug
export const getLesson = createAsyncThunk('lesson/getLesson', async (id) => {
    try {
        const response = await api.get(`/lesson/detail/${id}`);
        return response.data;
    } catch (error) {
        return isRejectedWithValue(error);
    }
});

export const getMarkedLesson = createAsyncThunk('lesson/getMarkedLesson', async (courseId) => {
    try {
        const response = await api.get(`/lesson/marked/${courseId}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const getLessonStats = createAsyncThunk('lesson/getLessonStats', async () => {
    try {
        const response = await api.get(`/lesson/stats`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const lessonSlice = createSlice({
    name: 'lesson',
    initialState: {
        lesson: {},
        markedLessons: [],
        lessonStats: [],
        loadingLesson: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get Lesson
            .addCase(getLesson.pending, (state) => {
                state.loadingLesson = true;
            })
            .addCase(getLesson.fulfilled, (state, action) => {
                state.loadingLesson = false;
                state.lesson = action.payload;
            })
            .addCase(getLesson.rejected, (state) => {
                state.loadingLesson = false;
            })

            // Get Marked Lessons
            .addCase(getMarkedLesson.pending, (state) => {
                state.loadingLesson = true;
            })
            .addCase(getMarkedLesson.fulfilled, (state, action) => {
                state.loadingLesson = false;
                state.markedLessons = action.payload;
            })
            .addCase(getMarkedLesson.rejected, (state) => {
                state.loadingLesson = false;
            })

            // Get Lesson Stats
            .addCase(getLessonStats.pending, (state) => {
                state.loadingLesson = true;
            })
            .addCase(getLessonStats.fulfilled, (state, action) => {
                state.loadingLesson = false;
                state.lessonStats = action.payload;
            })
            .addCase(getLessonStats.rejected, (state) => {
                state.loadingLesson = false;
            });
    }
});

export default lessonSlice.reducer;
