import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apps } from "../../utils/api";

export const getSkillOptions = createAsyncThunk('user/getSkillOptions', async () => {
    try {
        const response = await apps.get(`/api/entry/index/skills?perpage=1000`);
        return response.data.results;
    } catch (error) {
        throw new Error(error);
    };
});

export const getSkills = createAsyncThunk('user/getSkills', async () => {
    try {
        const response = await apps.get(`/api/entry/index/user_skill`);
        return response.data.results;
    } catch (error) {
        throw new Error(error);
    };
});

export const getSkill = createAsyncThunk('user/getSkill', async (id, { dispatch }) => {
    try {
        const response = await apps.get(`/api/entry/detail/user_skill/${id}`);
        dispatch(getSkills());
        return response.data.result;
    } catch (error) {
        throw new Error(error);
    }
});

export const postSkill = createAsyncThunk('user/postSkill', async (payload, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('skill', payload.skill);
        data.append('category', 'skill');
        data.append('level', payload.level);

        const response = await apps.post(`/api/entry/insert/user_skill`, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        dispatch(getSkills());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const updateSkill = createAsyncThunk('user/updateSkill', async (payload, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('skill', payload.skill);
        data.append('category', 'skill');
        data.append('level', payload.level);

        const response = await apps.post(`/api/entry/update/user_skill/${payload.id}`, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        dispatch(getSkills());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const deleteSkill = createAsyncThunk('user/deleteSkill', async (id, { dispatch }) => {
    try {
        const response = await apps.delete(`/api/entry/delete/user_skill/${id}`, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        dispatch(getSkills());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    skillOptions: [],
    skills: [],
    skill: {},
    postSkillAlert: {},
    updateSkillAlert: {},
    deleteSkillAlert: {},
    loadingSkills: false,
    loadingSkill: false
};

const skillSlice = createSlice({
    name: 'skill',
    initialState,
    reducers: {
        resetSkillState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            // Get Skill Options
            .addCase(getSkillOptions.pending, (state) => {
                state.loadingSkills = true;
            })
            .addCase(getSkillOptions.fulfilled, (state, action) => {
                state.loadingSkills = false;
                state.skillOptions = action.payload;
            })
            .addCase(getSkillOptions.rejected, (state) => {
                state.loadingSkills = false;
            })

            // Get Skills Array
            .addCase(getSkills.pending, (state) => {
                state.loadingSkills = true;
            })
            .addCase(getSkills.fulfilled, (state, action) => {
                state.loadingSkills = false;
                state.skills = action.payload;
            })
            .addCase(getSkills.rejected, (state) => {
                state.loadingSkills = false;
            })

            // Get Single Skill
            .addCase(getSkill.pending, (state) => {
                state.loadingSkill = true;
            })
            .addCase(getSkill.fulfilled, (state, action) => {
                state.loadingSkill = false;
                state.skill = action.payload;
            })
            .addCase(getSkill.rejected, (state) => {
                state.loadingSkill = false;
            })

            // Post Skill
            .addCase(postSkill.pending, (state) => {
                state.loadingSkills = true;
            })
            .addCase(postSkill.fulfilled, (state, action) => {
                state.loadingSkills = false;
                state.postSkillAlert = action.payload;
            })
            .addCase(postSkill.rejected, (state) => {
                state.loadingSkills = false;
            })

            // Update Skill
            .addCase(updateSkill.pending, (state) => {
                state.loadingSkills = true;
            })
            .addCase(updateSkill.fulfilled, (state, action) => {
                state.loadingSkills = false;
                state.updateSkillAlert = action.payload;
            })
            .addCase(updateSkill.rejected, (state) => {
                state.loadingSkills = false;
            })

            // Delete Skill
            .addCase(deleteSkill.pending, (state) => {
                state.loadingSkills = true;
            })
            .addCase(deleteSkill.fulfilled, (state, action) => {
                state.loadingSkills = false;
                state.deleteSkillAlert = action.payload;
            })
            .addCase(deleteSkill.rejected, (state) => {
                state.loadingSkills = false;
            });
    }
});

export const { resetSkillState } = skillSlice.actions;
export default skillSlice.reducer;
