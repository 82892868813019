import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

// Get All Tutorials
export const getAllTutorial = createAsyncThunk('lesson/getAllTutorial', async (limit) => {
    try {
        const response = await api.get(`/v1/posts/latest/post?page=1&limit=${limit || 100}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Popular Tutorials
export const getPopularTutorials = createAsyncThunk('lesson/getPopularTutorials', async () => {
    try {
        const response = await api.get(`/v1/posts/popularWeekly?limit=10`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const tutorialSlice = createSlice({
    name: 'tutorial',
    initialState: {
        tutorials: [],
        popularTutorials: [],
        loadingTutorial: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get All Tutorials
            .addCase(getAllTutorial.pending, (state) => {
                state.loadingTutorial = true;
            })
            .addCase(getAllTutorial.fulfilled, (state, action) => {
                state.loadingTutorial = false;
                state.tutorials = action.payload;
            })
            .addCase(getAllTutorial.rejected, (state, action) => {
                state.loadingTutorial = false;
                state.error = action.error?.message;
            })

            // Get Popular Tutorials
            .addCase(getPopularTutorials.pending, (state) => {
                state.loadingTutorial = true;
            })
            .addCase(getPopularTutorials.fulfilled, (state, action) => {
                state.loadingTutorial = false;
                if (action.payload.error !== "No Content") {
                    state.popularTutorials = action.payload;
                }
            })
            .addCase(getPopularTutorials.rejected, (state, action) => {
                state.loadingTutorial = false;
                state.error = action.error?.message;
            });
    }
});

export default tutorialSlice.reducer;
