import { createSlice } from '@reduxjs/toolkit'

const themeData = localStorage.getItem('theme') || 'light';

const initialState = {
    theme: themeData,
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme: (state, action) => {
            localStorage.setItem('theme', action.payload);
            state.theme = action.payload || themeData;
        },
        systemTheme: (state) => {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                // dark mode
                localStorage.setItem('theme', 'dark');
                state.theme = 'dark';
            } else {
                // light mode
                localStorage.setItem('theme', 'light');
                state.theme = 'light';
            };
        },
    },
});

// Action creators are generated for each case reducer function
export const { setTheme, systemTheme } = themeSlice.actions;
export default themeSlice.reducer;

