import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import Layout from "../../../components/global/Layout/Layout";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { apps } from "../../../utils/api";

const Redeem = () => {
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();

    const [voucher, setVoucher] = useState('');
    const [reference, setReference] = useState('');
    const [alert, setAlert] = useState({
        status: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);

    // Prefill Code by Query Params
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) setVoucher(code.toUpperCase());
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        let data = new FormData();
        data.append('voucher_code', voucher);
        data.append('reference', reference);

        const response = await apps.post(`/api/payment/voucher/claim`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });

        setLoading(false);
        setAlert({
            status: response.data.status,
            message: response.data.message
        });

        if (response.data.status === 'success') {
            navigate('/learn/courses');
            MySwal.fire({
                icon: 'success',
                title: 'Berhasil',
                text: "Hore! voucher berhasil di claim. Selamat belajar!"
            });
        };
    };

    return (
        <>
            <Helmet>
                <title>Redeem</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid vh-100 text-muted">
                        <h2 className="section-title h4">Redeem Voucher</h2>
                        <hr />
                        {alert.status === 'success' && (
                            <div className="alert alert-info fade show" role="alert">
                                <FontAwesomeIcon className="me-2" icon={faCheckCircle} /> {alert.message}
                            </div>
                        )}
                        {alert.status === 'failed' && (
                            <div className="alert alert-danger fade show" role="alert">
                                <FontAwesomeIcon className="me-2" icon={faWarning} /> {alert.message}
                            </div>
                        )}


                        <p>Terimakasih atas pembeliannya ya kak!</p>
                        <p className="mb-1">Boleh tahu kakak membeli produk kami dari referensi mana? Data ini sangat berarti bagi kami.</p>

                        <form className="mb-4">
                            {[
                                'Googling',
                                'Youtube Web Programming Unpas',
                                'Youtube Dea Afrizal',
                                'Youtube Kelas Terbuka',
                                'Instagram Codepolitan',
                                'Website Codepolitan',
                                'Referensi Teman/Keluarga',
                                'Program Beasiswa Codepolitan',
                                'Lain-lain',
                            ].map((item) => {
                                return (
                                    <div className="form-check" key={item}>
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            name={item}
                                            id={item}
                                            value={item}
                                            onChange={(e) => setReference(e.target.value)}
                                            checked={reference === item}
                                        />
                                        <label className="form-check-label" htmlFor={item}>
                                            {item}
                                        </label>
                                    </div>
                                );
                            })}
                        </form>

                        <div className="row">
                            <div className="col-lg-6">
                                <form className="d-flex gap-2" onSubmit={handleSubmit}>
                                    <input onChange={(e) => setVoucher(e.target.value?.toUpperCase())} value={voucher} className="form-control form-control-lg" type="text" placeholder="Masukkan kode voucher disini..." required />
                                    <button className="btn btn-primary btn-lg" type="submit" disabled={loading || !reference || !voucher}>{loading ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />) : 'Redeem'}</button>
                                </form>
                                <label className="text-muted my-2 small">Pilih referensi diatas untuk mengaktifkan tombol redeem.</label>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Redeem;
