import { useState, useEffect } from "react";
import { faArrowRightFromBracket, faBars, faBell, faClipboard, faFileInvoiceDollar, faFireAlt, faGear, faHeadset, faHome, faSearch, faTicket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import styles from './Navbar.module.scss';
import CardNotification from "../Cards/CardNotification/CardNotification";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../../features/notifications/notificationSlice";
import SkeleteonCardNotification from "../../skeletons/SkeletonCardNotification/SkeletonCardNotification";
import { getMyUser } from "../../../features/user/userSlice";

const Navbar = () => {
    const { myUser } = useSelector(state => state.user);
    const { notifications, loadingNotification } = useSelector(state => state.notifications);

    const [limitNotif, setLimitNotif] = useState(5);
    const [notifTime, setNotifTime] = useState(0);
    const [newNotifCount, setNewNotifCount] = useState(0);

    const percentageRank = Math.round((myUser?.point / myUser?.rank?.next_point) * 100);

    useEffect(() => {
        const savedNotifTime = localStorage.getItem('notifTime');
        if (savedNotifTime) {
            setNotifTime(Number(savedNotifTime));
        } else {
            setNotifTime(0);
        }
    }, []);

    useEffect(() => {
        if (notifTime !== null && notifications.length > 0) {
            const newNotifs = notifications.filter(notification => notification.rawdate > notifTime);
            setNewNotifCount(newNotifs.length);
        }
    }, [notifications, notifTime]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMyUser());
        dispatch(getNotifications());
    }, [dispatch]);

    const openNotif = () => {
        const currentTime = Math.floor(new Date().getTime() / 1000);
        setNotifTime(currentTime);
        localStorage.setItem('notifTime', currentTime);
        setNewNotifCount(0);
    };

    const onLogout = () => {
        Cookies.remove('jwt', '', { path: '', secure: true, sameSite: 'Lax' });
        window.location.href = `${process.env.REACT_APP_URL}/logout`;
    };

    return (
        <nav className="navbar navbar-expand-xl navbar-light bg-white sticky-top" id="navbar">
            <div className="container-fluid px-lg-5">
                <div className="d-flex gap-3 d-xl-none">
                    <a className="btn btn-white shadow-none px-0 d-xl-none my-auto" data-bs-toggle="offcanvas" href="#offcanvasSidebar" role="button" aria-controls="offcanvasSidebar">
                        <FontAwesomeIcon size="lg" className="text-muted" icon={faBars} />
                    </a>
                    <a className="navbar-brand position-relative my-auto" href={process.env.REACT_APP_URL}>
                        <img height={35} width={'auto'} className="d-md-none" src="/assets/img/codepolitan-logo-mobile.png" alt="Codepolitan" />
                        <img height={35} width={'auto'} className="d-none d-md-inline" src="/assets/img/codepolitan-logo.png" alt="Codepolitan" />
                    </a>
                </div>
                <ul className="nav me-auto d-flex gap-3 align-items-center">
                    <li className="nav-item text-muted d-none d-xl-flex gap-2 align-items-center">
                        Punya Voucher?
                        <Link className="btn btn-primary btn-sm" to="/user/redeem">
                            <FontAwesomeIcon icon={faTicket} /> Klaim Disini
                        </Link>
                    </li>
                    <li className="nav-item text-muted">
                        <Link className="btn btn-light btn-sm rounded-circle" to={`${process.env.REACT_APP_URL}/search`}>
                            <FontAwesomeIcon icon={faSearch} />
                        </Link>
                    </li>
                </ul>
                <ul className="nav ms-auto d-none d-xl-block border-end border-2">
                    <li className="nav-item">
                        <Link className="nav-link text-muted" to={`${process.env.REACT_APP_URL}/faq`}><FontAwesomeIcon className="me-1" icon={faHeadset} /> Butuh Bantuan?</Link>
                    </li>
                </ul>
                <ul className="nav">
                    <li className="nav-item dropdown order-last order-xl-first">
                        <a className="nav-link" href="!#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img height={45} width={45} className="rounded-circle objectfit-cover border-3" src={myUser?.avatar || "https://image.web.id/images/Avatar.jpg" || "/assets/img/placeholder.jpg"} alt="Profile" />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end shadow-sm mt-3" aria-labelledby="navbarDropdown" style={{ minWidth: '300px' }}>
                            <li className="px-3">
                                <div className="row mb-2">
                                    <div className="col-auto my-auto">
                                        <img height={50} width={50} className="img-fluid rounded-circle objectfit-cover border-3" src={myUser?.rank?.rank_picture || "/assets/img/placeholder.jpg"} alt="Profile" />
                                    </div>
                                    <div className="col my-auto ps-0">
                                        <small className="text-primary mb-0">{myUser?.rank?.rank_name}</small>
                                        <p className="fw-bolder mb-2 text-uppercase">{myUser?.name}</p>
                                        <div className="progress" style={{ background: '#FEEEF0' }}>
                                            <div className="progress-bar d-inline" role="progressbar" style={{ width: `${percentageRank}%`, background: '#FF8B99' }} aria-valuenow={percentageRank} aria-valuemin="0" aria-valuemax="100"><FontAwesomeIcon icon={faFireAlt} className="d-inline" /> {myUser?.point} Exp</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-grid">
                                    <Link className="btn btn-outline-secondary btn-sm" to={`${process.env.REACT_APP_URL}/coders/${myUser && myUser.username}`}>Lihat Profil</Link>
                                </div>
                            </li>
                            <li><hr className="dropdown-divider" /></li>
                            <li className="my-2">
                                <Link className="dropdown-item text-muted" to={`${process.env.REACT_APP_URL}/home`}>
                                    <FontAwesomeIcon fixedWidth icon={faHome} /> Beranda
                                </Link>
                            </li>
                            <li className="my-2">
                                <Link className="dropdown-item text-muted" to="/user/point-history">
                                    <FontAwesomeIcon fixedWidth icon={faFireAlt} /> Riwayat Poin
                                </Link>
                            </li>
                            <li className="my-2">
                                <Link className="dropdown-item text-muted" to="/user/invoice">
                                    <FontAwesomeIcon fixedWidth icon={faFileInvoiceDollar} /> Riwayat Pembelian
                                </Link>
                            </li>
                            <li className="my-2">
                                <Link className="dropdown-item text-muted" to="/user/notes">
                                    <FontAwesomeIcon fixedWidth icon={faClipboard} /> Catatan Saya
                                </Link>
                            </li>
                            <li><hr className="dropdown-divider" /></li>
                            <li className="my-2">
                                <Link className="dropdown-item text-muted" to="/user/settings">
                                    <FontAwesomeIcon fixedWidth icon={faGear} /> Pengaturan
                                </Link>
                            </li>
                            <li><hr className="dropdown-divider" /></li>
                            <li className="my-2">
                                <button type="button" className="dropdown-item text-muted" onClick={onLogout}>
                                    <FontAwesomeIcon fixedWidth icon={faArrowRightFromBracket} /> Keluar
                                </button>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown my-auto">
                        <a onClick={openNotif} className="nav-link" href="!#" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                            {newNotifCount > 0 && <span className={styles.notification_indicator} />}
                            <FontAwesomeIcon size="lg" icon={faBell} style={{ color: '#a6b1bf' }} />
                        </a>
                        <ul className={`dropdown-menu dropdown-menu-end shadow-sm mt-4 ${styles.dropdown_notification} overflow-auto`} aria-labelledby="navbarDropdown">
                            <li className="d-flex justify-content-between">
                                <small className="text-muted text-uppercase px-3 my-auto">Notifikasi</small>
                                <Link className="nav-link" to="/notifications">Lihat Semua</Link>
                            </li>
                            {loadingNotification && [1, 2, 3]?.map(item => (
                                <li className="dropdown-item text-wrap" key={item}>
                                    <SkeleteonCardNotification />
                                </li>
                            ))}
                            {!loadingNotification && notifications?.map((notification, index) => (
                                <li className="dropdown-item text-wrap" key={index}>
                                    <CardNotification
                                        navbar
                                        type={notification.type}
                                        message={notification && notification.meta.message}
                                        date={notification.date}
                                        rawdate={notification.rawdate}
                                        uri={notification.meta.uri}
                                        notifTime={notifTime}
                                    />
                                </li>
                            )).slice(0, limitNotif)}
                            {!loadingNotification && notifications?.length > limitNotif && (
                                <div className="dropdown-item d-grid gap-2">
                                    <button className="btn btn-outline-secondary shadow-none" type="button" onClick={() => setLimitNotif(limitNotif + 10)}>Load more</button>
                                </div>
                            )}
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;