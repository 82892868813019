import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

export const getLeaderboard = createAsyncThunk('leaderboard/getLeaderboard', async (period, { getState }) => {
    const state = getState();
    const cachedData = state.leaderboards.leaderboard[period];

    if (cachedData) {
        return { period, data: cachedData };
    };

    // Fetch data if not cached
    try {
        const response = await api.get(`/v1/points/leaderboard?page=1&period=${period}`);
        return { period, data: response.data };

    } catch (error) {
        throw new Error(error);
    }
});

export const getCurrentUserPoint = createAsyncThunk('user/getCurrentUserPoint', async (period) => {
    try {
        const response = await api.get(`/v1/points/current?period=${period}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const getUserPointHistory = createAsyncThunk('user/getUserPointHistory', async (page) => {
    try {
        const response = await api.get(`/v1/points?page=${page}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState: {
        leaderboard: {
            week: null,
            month: null,
            overall: null,
        },
        currentUserPoint: {},
        userPointHistory: [],
        loadingLeaderboard: false,
        loadingCurrentUserPoint: false,
        loadingUserPointHistory: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Leaderboard (weekly, monthly, overall)
        builder
            .addCase(getLeaderboard.pending, (state) => {
                state.loadingLeaderboard = true;
            })
            .addCase(getLeaderboard.fulfilled, (state, action) => {
                state.loadingLeaderboard = false;
                const { period, data } = action.payload;
                state.leaderboard[period] = !data.error ? data : null;
            })
            .addCase(getLeaderboard.rejected, (state) => {
                state.loadingLeaderboard = false;
            });

        // Current User Point
        builder
            .addCase(getCurrentUserPoint.pending, (state) => {
                state.loadingCurrentUserPoint = true;
            })
            .addCase(getCurrentUserPoint.fulfilled, (state, action) => {
                state.loadingCurrentUserPoint = false;
                state.currentUserPoint = !action.payload.error ? action.payload : {};
            })
            .addCase(getCurrentUserPoint.rejected, (state) => {
                state.loadingCurrentUserPoint = false;
            });

        // User Point History
        builder
            .addCase(getUserPointHistory.pending, (state) => {
                state.loadingUserPointHistory = true;
            })
            .addCase(getUserPointHistory.fulfilled, (state, action) => {
                state.loadingUserPointHistory = false;

                const currentPointHistory = [...state.userPointHistory];
                const index = currentPointHistory.findIndex(
                    (search) => search.created_at === action.payload[0].created_at
                );

                if (index > -1) {
                    currentPointHistory[index].created_at = action.payload[index].created_at;
                } else {
                    currentPointHistory.push(...action.payload);
                }

                state.userPointHistory = currentPointHistory;
            })
            .addCase(getUserPointHistory.rejected, (state) => {
                state.loadingUserPointHistory = false;
            });
    }

});

export default leaderboardSlice.reducer;
