import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

// Get All Events
export const getAllEvents = createAsyncThunk('lesson/getAllEvents', async () => {
    try {
        const response = await api.get(`/v1/webinar/latest?page=1&limit=100`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const eventSlice = createSlice({
    name: 'event',
    initialState: {
        events: [],
        loadingEvent: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get All Events
            .addCase(getAllEvents.pending, (state) => {
                state.loadingEvent = true;
            })
            .addCase(getAllEvents.fulfilled, (state, action) => {
                state.loadingEvent = false;
                state.events = action.payload;
            })
            .addCase(getAllEvents.rejected, (state) => {
                state.loadingEvent = false;
            });
    }
});

export default eventSlice.reducer;
