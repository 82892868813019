
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
import Layout from "../../../components/global/Layout/Layout";
import { capitalizeFirstLetter, formatDate, formatPrice } from "../../../utils/helper";
import { apps } from "../../../utils/api";

const Invoice = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getOrders = async () => {
            setLoading(true);
            try {
                const response = await apps.get(`/api/payment/orders?page=1`);
                setLoading(false);
                setOrders(response.data);
            } catch (error) {
                throw new Error(error);
            }
        }
        getOrders();
    }, []);

    return (
        <>
            <Helmet>
                <title>Riwayat Pembelian</title>
            </Helmet>
            <Layout>
                <section className="section mt-5">
                    <div className="container-fluid">
                        <h2 className="section-title h4">Riwayat Pembelian</h2>
                        <hr />
                        <div className="card border-0 card-rounded my-4">
                            <div className="card-body px-0 overflow-auto">
                                <table className="table table-striped table-hover table-borderless">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10%' }}>Invoice ID</th>
                                            <th style={{ width: '30%' }}>Nama Produk</th>
                                            <th style={{ width: '10%' }}>Total</th>
                                            <th style={{ width: '10%' }}>Waktu</th>
                                            <th style={{ width: '10%' }}>Status</th>
                                            <th style={{ width: '10%' }}>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading && [1, 2, 3].map((index) => {
                                            return (
                                                <tr className="placeholder-glow" key={index}>
                                                    <td><span className="placeholder col-6 rounded-pill" /></td>
                                                    <td><span className="placeholder col-3 rounded-pill" /></td>
                                                    <td><span className="placeholder col-6 rounded-pill" /></td>
                                                    <td><span className="placeholder col-6 rounded-pill" /></td>
                                                    <td><span className="placeholder col-6 rounded-pill" /></td>
                                                    <td><span className="placeholder col-6 rounded-pill" /></td>
                                                </tr>
                                            );
                                        })}
                                        {!loading && orders?.length > 0 && orders?.map((order, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="text-muted">{order.order_code}</td>
                                                    <td className="text-muted">{order.product_name}</td>
                                                    <td className="text-muted">Rp {formatPrice(order.gross_amount)}</td>
                                                    <td className="text-muted">{formatDate(order.created_at)}</td>
                                                    <td className={order.transaction_status === 'expired' ? 'text-danger' : 'text-primary'}>{capitalizeFirstLetter(order.transaction_status)}</td>
                                                    <td>
                                                        <Link className="btn btn-outline-primary btn-sm" to={`/user/invoice/${order.order_code}`}>Lihat Invoice</Link>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        {!loading && orders.length < 1 && (
                                            <tr className="text-center">
                                                <td className="text-muted" colSpan={6}>Tidak ada riwayat pembelian</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Invoice;
