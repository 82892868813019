import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apps } from "../../utils/api";

// Get Menror Courses
export const getMentorCourses = createAsyncThunk('mentor/getMentorCourses', async () => {
    try {
        const response = await apps.get(`/api/course`);
        return response.data.results;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Course
export const getMentorCourse = createAsyncThunk('mentor/getMentorCourse', async (id) => {
    try {
        const response = await apps.get(`/api/course/detail/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Post Mentor Course
export const postMentorCourse = createAsyncThunk('mentor/postMentorCourse', async (payload, { dispatch }) => {
    try {
        let data = {
            'course_title': payload.courseTitle,
            'slug': payload.courseSlug
        }

        const response = await apps.post(`/api/course/insert`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getMentorCourses());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Update Mentor Course
export const updateMentorCourse = createAsyncThunk('mentor/updateMentorCourse', async (payload, { dispatch }) => {
    try {

        let data = {
            "id": payload.id,
            "course_title": payload.courseTitle,
            "slug": payload.courseSlug,
            "level": payload.level,
            "label": payload.label,
            "cover": payload.cover,
            "thumbnail": payload.thumbnail,
            "description": payload.description,
            "long_description": payload.longDescription,
            "total_module": payload.totalModule,
            "total_time": payload.totalTime,
            "author": payload.author,
            "author_contact": payload.authorContact,
            "author_email": payload.authorEmail,
            "sourcecode_url": payload.sourcecodeUrl,
            "preview_url": payload.previewUrl,
            "project_screenshot": payload.projectScreenshot,
            "preview_video": payload.previewVideo,
            "preview_video_cover": payload.previewVideoCover,
            "video_screenshots": payload.videoScreenshot,
            "tags": payload.tags
        };

        const response = await apps.post(`/api/course/update`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getMentorCourse(payload.id));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Delete Mentor Course
export const deleteMentorCourse = createAsyncThunk('mentor/deleteMentorCourse', async (id, { dispatch }) => {
    try {
        const response = await apps.delete(`/api/course/deleteCourse?id=${id}`);
        dispatch(getMentorCourses());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// getTopic + Lessons
export const getTopicAndLessons = createAsyncThunk('mentor/getTopicAndLessons', async (id) => {
    try {
        const response = await apps.get(`/api/course/lessons/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Topic Detail
export const getTopic = createAsyncThunk('mentor/getTopic', async (id) => {
    try {
        const response = await apps.get(`/api/course/getTopic?id=${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Post Topic
export const postTopic = createAsyncThunk('mentor/postTopic', async (payload, { dispatch }) => {
    try {
        let data = {
            "course_id": payload.id,
            "topic_title": payload.topicTitle,
            "topic_order": payload.topicOrder,
            "free": payload.free === true ? "1" : "0"
        };

        const response = await apps.post(`/api/course/saveTopic`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getTopicAndLessons(payload.id));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Update Topic
export const updateTopic = createAsyncThunk('mentor/updateTopic', async (payload, { dispatch }) => {
    try {
        let data = {
            "course_id": payload.id,
            "topic_title": payload.topicTitle,
            "topic_order": payload.topicOrder,
            "free": payload.free
        };

        const response = await apps.post(`/api/course/saveTopic/${payload.topicId}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getTopicAndLessons(payload.id));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Delete Topic
export const deleteTopic = createAsyncThunk('mentor/deleteTopic', async (id) => {
    try {
        const response = await apps.delete(`/api/course/deleteTopic?id=${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    };
});

// Move Up Topic
export const moveUpTopic = createAsyncThunk('mentor/moveUpTopic', async (payload, { dispatch }) => {
    try {
        const response = await apps.post(`/api/course/moveup_topic?course_id=${payload.courseId}&topic_id=${payload.topicId}&position=${payload.position}`, null, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getTopicAndLessons(payload.courseId));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Move Down Topic
export const moveDownTopic = createAsyncThunk('mentor/moveDownTopic', async (payload, { dispatch }) => {
    try {
        const response = await apps.post(`/api/course/movedown_topic?course_id=${payload.courseId}&topic_id=${payload.topicId}&position=${payload.position}`, null, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getTopicAndLessons(payload.courseId));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Post Lesson
export const postLesson = createAsyncThunk('mentor/postLesson', async (payload, { dispatch }) => {
    try {
        let data = {
            "course_id": payload.courseId,
            "topic_id": payload.topicId,
            "free": payload.free === true ? "1" : "0",
            "lesson_title": payload.lessonTitle,
            "lesson_slug": payload.lessonSlug,
            "lesson_order": payload.lessonOrder,
            "type": payload.type,
            "youtube_id": payload.youtubeId,
            "vdocipher_id": payload.vdocipherId,
            "bunnystream_id": payload.bunnystreamId,
            "player": payload.player,
            "duration": payload.duration,
            "theory": payload.theory
        };

        const response = await apps.post(`/api/course/saveLesson`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getTopicAndLessons(payload.courseId));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Lesson Detail
export const getLesson = createAsyncThunk('mentor/getLesson', async (id) => {
    try {
        const response = await apps.get(`/api/course/getLesson?lesson_id=${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Update Lesson
export const updateLesson = createAsyncThunk('mentor/updateLesson', async (payload, { dispatch }) => {
    try {
        let data = {
            "course_id": payload.courseId,
            "topic_id": payload.topicId,
            "free": payload.free === true ? "1" : "0",
            "lesson_title": payload.lessonTitle,
            "lesson_slug": payload.lessonSlug,
            "lesson_order": payload.lessonOrder,
            "type": payload.type,
            "youtube_id": payload.youtubeId,
            "vdocipher_id": payload.vdocipherId,
            "bunnystream_id": payload.bunnystreamId,
            "player": payload.player,
            "duration": payload.duration,
            "theory": payload.theory
        };

        const response = await apps.post(`/api/course/saveLesson/${payload.lessonId}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getTopicAndLessons(payload.courseId));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Delete Lesson or Quiz
export const deleteLesson = createAsyncThunk('mentor/deleteLesson', async (id) => {
    try {
        const response = await apps.delete(`/api/course/deleteLesson?id=${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Move Up Lesson
export const moveUpLesson = createAsyncThunk('mentor/moveUpLesson', async (payload, { dispatch }) => {
    try {
        const response = await apps.post(`/api/course/moveup_lesson?topic_id=${payload.topicId}&lesson_id=${payload.lessonId}&position=${payload.position}`, null, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getTopicAndLessons(payload.courseId));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Move Down Topic
export const moveDownLesson = createAsyncThunk('mentor/moveDownLesson', async (payload, { dispatch }) => {
    try {
        const response = await apps.post(`/api/course/movedown_lesson?topic_id=${payload.topicId}&lesson_id=${payload.lessonId}&position=${payload.position}`, null, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getTopicAndLessons(payload.courseId));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Post Quiz
export const postQuiz = createAsyncThunk('mentor/postQuiz', async (payload, { dispatch }) => {
    try {
        let data = {
            "course_id": payload.courseId,
            "topic_id": payload.topicId,
            "title": payload.quizTitle,
            "slug": payload.quizSlug,
            "level": payload.quizLevel,
            "category": payload.quizCategory,
            "type": payload.questionType,
            "cover": payload.quizCover,
            "label": payload.quizLabel,
            "subtitle": payload.quizSubtitle,
            "duration": payload.quizDuration,
            "description": payload.quizDescription,
            "kkm": payload.quizKKM,
            "total_show": payload.quizTotalShow,
            "random_question": payload.free === true ? "1" : "0",
            "status": payload.quizStatus
        };

        const response = await apps.post(`/api/quiz/insert`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getTopicAndLessons(payload.courseId));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Quiz
export const getQuiz = createAsyncThunk('mentor/getQuiz', async (id) => {
    try {
        const response = await apps.get(`/api/quiz/getQuiz/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Update Quiz
export const updateQuiz = createAsyncThunk('mentor/updateQuiz', async (payload) => {
    try {
        let data = {
            "title": payload.quizTitle,
            "slug": payload.quizSlug,
            "level": payload.quizLevel,
            "category": payload.quizCategory,
            "type": payload.questionType,
            "cover": payload.quizCover,
            "label": payload.quizLabel,
            "subtitle": payload.quizSubtitle,
            "duration": payload.quizDuration,
            "description": payload.quizDescription,
            "kkm": payload.quizKKM,
            "total_show": payload.quizTotalShow,
            "random_question": payload.randomQuestion,
            "status": payload.quizStatus
        };

        const response = await apps.put(`/api/quiz/updateQuiz/${payload.quizId}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Post Question
export const postQuestion = createAsyncThunk('mentor/postQuestion', async (payload) => {
    try {
        let data = {
            "group_id": payload.quizId,
            "question_title": payload.questionTitle,
            "question_content": payload.questionContent,
            "hint": payload.questionHint,
            "options": payload.options
        };

        const response = await apps.post(`/api/quiz/saveQuestion`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get detail question
export const getQuestion = createAsyncThunk('mentor/getQuestion', async (id) => {
    try {
        const response = await apps.get(`/api/quiz/getQuestion/${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Update Question
export const updateQuestion = createAsyncThunk('mentor/updateQuestion', async (payload) => {
    try {
        let data = {
            "id": payload.questionId,
            "question_title": payload.questionTitle,
            "question_content": payload.questionContent,
            "hint": payload.questionHint,
            "options": payload.options
        };

        const response = await apps.put(`/api/quiz/updateQuestion`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Delete Question
export const deleteQuestion = createAsyncThunk('mentor/deleteQuestion', async ({ groupId, questionId }) => {
    try {
        const response = await apps.delete(`/api/quiz/deleteQuestion?group_id=${groupId}&question_id=${questionId}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const mentorCourseSlice = createSlice({
    name: 'mentorCourse',
    initialState: {
        mentorCourses: [],
        mentorCourse: [],
        topicAndLessons: [],
        postMentorCourseResult: {},
        updateMentorCourseResult: {},
        loadingMentorCourse: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get Mentor Courses
            .addCase(getMentorCourses.pending, (state) => {
                state.loadingMentorCourse = true;
            })
            .addCase(getMentorCourses.fulfilled, (state, action) => {
                state.loadingMentorCourse = false;
                state.mentorCourses = action.payload;
            })
            .addCase(getMentorCourses.rejected, (state, action) => {
                state.loadingMentorCourse = false;
                state.error = action.error?.message;
            })

            // Get Detail Course
            .addCase(getMentorCourse.pending, (state) => {
                state.loadingMentorCourse = true;
            })
            .addCase(getMentorCourse.fulfilled, (state, action) => {
                state.loadingMentorCourse = false;
                state.mentorCourse = action.payload;
            })
            .addCase(getMentorCourse.rejected, (state, action) => {
                state.loadingMentorCourse = false;
                state.error = action.error?.message;
            })

            // Post new course
            .addCase(postMentorCourse.pending, (state) => {
                state.loadingMentorCourse = true;
            })
            .addCase(postMentorCourse.fulfilled, (state, action) => {
                state.loadingMentorCourse = false;
                state.postMentorCourseResult = action.payload;
            })
            .addCase(postMentorCourse.rejected, (state, action) => {
                state.loadingMentorCourse = false;
                state.error = action.error?.message;
            })

            // Update course
            .addCase(updateMentorCourse.pending, (state) => {
                state.loadingMentorCourse = true;
            })
            .addCase(updateMentorCourse.fulfilled, (state, action) => {
                state.loadingMentorCourse = false;
                state.updateMentorCourseResult = action.payload;
            })
            .addCase(updateMentorCourse.rejected, (state, action) => {
                state.loadingMentorCourse = false;
                state.error = action.error?.message;
            })

            // Get Topic and Lessons
            .addCase(getTopicAndLessons.pending, (state) => {
                state.loadingMentorCourse = true;
            })
            .addCase(getTopicAndLessons.fulfilled, (state, action) => {
                state.loadingMentorCourse = false;
                state.topicAndLessons = action.payload;
            })
            .addCase(getTopicAndLessons.rejected, (state, action) => {
                state.loadingMentorCourse = false;
                state.error = action.error?.message;
            })

            // Get Topic
            .addCase(getTopic.pending, (state) => {
                state.loadingMentorCourse = true;
            })
            .addCase(getTopic.fulfilled, (state, action) => {
                state.loadingMentorCourse = false;
            })
            .addCase(getTopic.rejected, (state, action) => {
                state.loadingMentorCourse = false;
                state.error = action.error?.message;
            })

            // Post Topic
            .addCase(postTopic.pending, (state) => {
                state.loadingMentorCourse = true;
            })
            .addCase(postTopic.fulfilled, (state, action) => {
                state.loadingMentorCourse = false;
            })
            .addCase(postTopic.rejected, (state, action) => {
                state.loadingMentorCourse = false;
                state.error = action.error?.message;
            })

            // Update Topic
            .addCase(updateTopic.pending, (state) => {
                state.loadingMentorCourse = true;
            })
            .addCase(updateTopic.fulfilled, (state, action) => {
                state.loadingMentorCourse = false;
            })
            .addCase(updateTopic.rejected, (state, action) => {
                state.loadingMentorCourse = false;
                state.error = action.error?.message;
            });
    }
});

export default mentorCourseSlice.reducer;
