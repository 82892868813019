import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

export const getLatestLearn = createAsyncThunk('user/getLatestLearn', async () => {
    try {
        const response = await api.get(`/course/state/last`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const learnSlice = createSlice({
    name: 'learn',
    initialState: {
        latestLearn: [],
        loadingLearn: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLatestLearn.pending, (state) => {
                state.loadingLearn = true;
            })
            .addCase(getLatestLearn.fulfilled, (state, action) => {
                state.latestLearn = action.payload;
                state.loadingLearn = false;
            })
            .addCase(getLatestLearn.rejected, (state) => {
                state.loadingLearn = false;
            })
    }
});

export default learnSlice.reducer;
