import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api, apps } from "../../utils/api";

// Get All Submission
export const getAllSubmission = createAsyncThunk('submission/getAllSubmission', async () => {
    try {
        const response = await apps.get(`/api/entry/index/codepolitan_submission`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data.results;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Submission
export const getSubmissionDetail = createAsyncThunk('submission/getSubmissionDetail', async (id) => {
    try {
        const response = await apps.get(`/api/entry/detail/codepolitan_submission/${id}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data.result;
    } catch (error) {
        throw new Error(error);
    }
});

// Post Submission Data
export const postSubmissionData = createAsyncThunk('submission/postSubmissionData', async (inputState, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('title', inputState.title);
        data.append('category', 'kelasfullstack');
        data.append('github_link', inputState.github);
        data.append('screenshot', inputState.screenshotSubmission);

        const response = await apps.post(`/api/entry/insert/codepolitan_submission`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch(getAllSubmission())
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Update Submission Data
export const updateSubmissionData = createAsyncThunk('submission/updateSubmissionData', async (inputState, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('title', inputState.title);
        data.append('category', 'kelasfullstack');
        data.append('github_link', inputState.github);
        data.append('screenshot', inputState.screenshotSubmission);

        const response = await apps.post(`/api/entry/update/codepolitan_submission/${inputState.id}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch(getAllSubmission())
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Delete Submission
export const deleteSubmission = createAsyncThunk('submission/deleteSubmission', async (id, { dispatch }) => {
    try {
        const response = await apps.delete(`/api/entry/delete/codepolitan_submission/${id}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        dispatch(getAllSubmission());
        return response.data.result;
    } catch (error) {
        throw new Error(error);
    }
});

// Check Subscribe Expired
export const checkSubscribeExpired = createAsyncThunk('submission/checkSubscribeExpired', async () => {
    try {
        const response = await api.get(`/task-check`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const submissionSlice = createSlice({
    name: 'Submission Kelas Fullstack',
    initialState: {
        loadingSubmission: false,
        submissions: [],
        subscribeExpired: {}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get All Submission
            .addCase(getAllSubmission.pending, (state) => {
                state.loadingSubmission = true;
            })
            .addCase(getAllSubmission.fulfilled, (state, action) => {
                state.loadingSubmission = false;
                state.submissions = action.payload;
            })
            .addCase(getAllSubmission.rejected, (state, action) => {
                state.loadingSubmission = false;
                state.error = action.error?.message;
            })

            // Post Submission
            .addCase(postSubmissionData.pending, (state) => {
                state.loadingSubmission = true;
            })
            .addCase(postSubmissionData.fulfilled, (state) => {
                state.loadingSubmission = false;
            })
            .addCase(postSubmissionData.rejected, (state, action) => {
                state.loadingSubmission = false;
                state.error = action.error?.message;
            })

            // Update Submission
            .addCase(updateSubmissionData.pending, (state) => {
                state.loadingSubmission = true;
            })
            .addCase(updateSubmissionData.fulfilled, (state) => {
                state.loadingSubmission = false;
            })
            .addCase(updateSubmissionData.rejected, (state, action) => {
                state.loadingSubmission = false;
                state.error = action.error?.message;
            })

            // Check Subscribe Expired
            .addCase(checkSubscribeExpired.pending, (state) => {
                state.loadingSubmission = true;
            })
            .addCase(checkSubscribeExpired.fulfilled, (state, action) => {
                state.loadingSubmission = false;
                if (action.payload.error !== "No Content") {
                    state.subscribeExpired = action.payload;
                }
            })
            .addCase(checkSubscribeExpired.rejected, (state, action) => {
                state.loadingSubmission = false;
                state.error = action.error?.message;
            });
    }
});

export default submissionSlice.reducer;