import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

// Get Course by slug
export const checkCompletedCourse = createAsyncThunk('checkCourse/checkCompletedCourse', async (courseId) => {
    try {
        const response = await api.get(`/course/isCompleted?course_id=${courseId}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const getCurrentCourseState = createAsyncThunk('checkCourse/getCurrentCourseState', async (courseId) => {
    try {
        const response = await api.get(`/course/state/${courseId}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const courseCheckSlice = createSlice({
    name: 'checkCourse',
    initialState: {
        completedCourse: {},
        currentCourseState: {},
        loadingCourse: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get Single Course
            .addCase(checkCompletedCourse.pending, (state) => {
                state.loadingCourse = true;
            })
            .addCase(checkCompletedCourse.fulfilled, (state, action) => {
                state.loadingCourse = false;
                state.completedCourse = action.payload;
            })
            .addCase(checkCompletedCourse.rejected, (state) => {
                state.loadingCourse = false;
            })

            // Get Current Course State
            .addCase(getCurrentCourseState.pending, (state) => {
                state.loadingCourse = true;
            })
            .addCase(getCurrentCourseState.fulfilled, (state, action) => {
                state.loadingCourse = false;
                state.currentCourseState = action.payload;
            })
            .addCase(getCurrentCourseState.rejected, (state) => {
                state.loadingCourse = false;
            });
    }
});

export default courseCheckSlice.reducer;
