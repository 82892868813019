import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userId } from "../../utils/config";
import { apps } from "../../utils/api";

// Get Discussion By Lesson
export const getDiscussionByLesson = createAsyncThunk('discussion/getDiscussionByLesson', async (id) => {
    try {
        const response = await apps.get(`/api/forum/thread/lesson/${id}`);
        return response.data !== '' ? response.data : [];
    } catch (error) {
        throw new Error(error);
    }
});

// Get Discussion By User ID
export const getMyDiscussions = createAsyncThunk('discussion/getMyDiscussions', async () => {
    const id = userId;

    try {
        const response = await apps.get(`/api/forum/thread?id=${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get All Discussion
export const getAllDiscussion = createAsyncThunk('discussion/getAllDiscussion', async (id) => {
    try {
        const response = await apps.get(`/api/forum/thread`)
        return response.data
    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Discussion
export const getDetailDiscussion = createAsyncThunk('discussion/getDetailDiscussion', async (slug) => {
    try {
        const response = await apps.get(`/api/forum/thread/detail/${slug}`)
        return response.data
    } catch (error) {
        throw new Error(error);
    }
});

// Post new discussion
export const postDiscussion = createAsyncThunk('discussion/postDiscussion', async ({ courseId, lessonId, threadSubject, threadContent, channelId }, { dispatch }) => {
    try {
        let data = {
            "course_id": courseId,
            "lesson_id": lessonId,
            "thread_subject": threadSubject,
            "thread_content": threadContent,
            "thread_mode": "md",
            "channel_id": channelId ?? ""
        };

        const response = await apps.post(`/api/forum/thread/insert`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getDiscussionByLesson(lessonId));
        return response.data;

    } catch (error) {
        throw new Error(error);
    }
});

// Update discussion
export const updateDiscussion = createAsyncThunk('discussion/updateDiscussion', async ({ id, thread_subject, thread_content, slug, channel_id }, { dispatch }) => {
    const data = { id, thread_subject, thread_content, channel_id: channel_id ?? "" }
    try {
        const response = await apps.patch(`/api/forum/thread/update`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getDetailDiscussion(slug));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Report discussion
export const reportDiscussion = createAsyncThunk('discussion/reportDiscussion', async ({ meta, issue, note, object_id, object_type, slug }, { dispatch }) => {
    const data = { meta, issue, note, object_id, object_type }
    try {
        const response = await apps.patch(`/api/forum/flag/insert`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getDetailDiscussion(slug));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Reply discussion
export const replyDiscussion = createAsyncThunk('discussion/replyDiscussion', async ({ thread_id, reply_mode, reply_content, slug }, { dispatch }) => {
    const data = { thread_id, reply_mode, reply_content }
    try {
        const response = await apps.patch(`/api/forum/reply/insert`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getDetailDiscussion(slug));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Reply discussion comment
export const commentDiscussion = createAsyncThunk('discussion/commentDiscussion', async ({ thread_id, comment_content, object_id, object_type, slug }, { dispatch }) => {
    const data = { thread_id, comment_content, object_id, object_type }
    try {
        const response = await apps.patch(`/api/forum/comment/insert`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getDetailDiscussion(slug));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Update discussion reply
export const updateReplyDiscussion = createAsyncThunk('discussion/updateReplyDiscussion', async ({ id, reply_content, slug }, { dispatch }) => {
    const data = { id, reply_content }
    try {
        const response = await apps.patch(`/api/forum/reply/update`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getDetailDiscussion(slug));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Update discussion comment
export const updateCommentDiscussion = createAsyncThunk('discussion/updateCommentDiscussion', async ({ id, comment_content, slug }, { dispatch }) => {
    const data = { id, comment_content }
    try {
        const response = await apps.patch(`/api/forum/comment/update`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getDetailDiscussion(slug));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Mark as best reply
export const markAsBestReply = createAsyncThunk('discussion/markAsBestReply', async ({ thread_id, reply_id, slug }, { dispatch }) => {
    const data = { thread_id, reply_id }
    try {
        const response = await apps.post(`/api/forum/reply/approve`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getDetailDiscussion(slug));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Delete discussion
export const deleteDiscussion = createAsyncThunk('discussion/deleteDiscussion', async ({ thread_id, slug }, { dispatch }) => {
    try {
        const response = await apps.delete(`/api/forum/thread/remove/${thread_id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getDetailDiscussion(slug));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Delete discussion reply
export const deleteDiscussionReply = createAsyncThunk('discussion/deleteDiscussionReply', async ({ thread_id, reply_id, slug }, { dispatch }) => {
    try {
        const response = await apps.delete(`/api/forum/reply/remove/${thread_id}/${reply_id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getDetailDiscussion(slug));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Delete discussion reply
export const deleteDiscussionComment = createAsyncThunk('discussion/deleteDiscussionComment', async ({ comment_id, slug }, { dispatch }) => {
    try {
        const response = await apps.delete(`/api/forum/comment/remove/${comment_id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        dispatch(getDetailDiscussion(slug));
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    discussions: [],
    discussion: [],
    myDiscussions: [],
    lessonDiscussions: [],
    postDiscussionResult: {},
    updateDiscussionResult: [],
    loadingDiscussion: false,
    loadingDelete: false,
    loadingMark: false
};

const discussionSlice = createSlice({
    name: 'discussion',
    initialState,
    reducers: {
        resetDiscussionState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            // Get Single Course Discussion
            .addCase(getDiscussionByLesson.pending, (state) => {
                state.loadingDiscussion = true;
            })
            .addCase(getDiscussionByLesson.fulfilled, (state, action) => {
                state.loadingDiscussion = false;
                state.lessonDiscussions = action.payload;
            })
            .addCase(getDiscussionByLesson.rejected, (state) => {
                state.loadingDiscussion = false;
            })

            // Get All Discussion
            .addCase(getAllDiscussion.pending, (state) => {
                state.loadingDiscussion = true;
            })
            .addCase(getAllDiscussion.fulfilled, (state, action) => {
                state.loadingDiscussion = false;
                state.discussions = action.payload !== "" ? action.payload : [];
            })
            .addCase(getAllDiscussion.rejected, (state) => {
                state.loadingDiscussion = false;
            })

            // Get My Discussion By User ID
            .addCase(getMyDiscussions.pending, (state) => {
                state.loadingDiscussion = true;
            })
            .addCase(getMyDiscussions.fulfilled, (state, action) => {
                state.loadingDiscussion = false;
                state.myDiscussions = action.payload;
            })
            .addCase(getMyDiscussions.rejected, (state) => {
                state.loadingDiscussion = false;
            })

            // Get Detail Discussion
            .addCase(getDetailDiscussion.pending, (state) => {
                state.loadingDiscussion = true;
            })
            .addCase(getDetailDiscussion.fulfilled, (state, action) => {
                state.loadingDiscussion = false;
                state.discussion = action.payload;
            })
            .addCase(getDetailDiscussion.rejected, (state) => {
                state.loadingDiscussion = false;
            })

            // Post Discussion
            .addCase(postDiscussion.pending, (state) => {
                state.loadingDiscussion = true;
            })
            .addCase(postDiscussion.fulfilled, (state, action) => {
                state.loadingDiscussion = false;
                state.postDiscussionResult = action.payload;
            })
            .addCase(postDiscussion.rejected, (state) => {
                state.loadingDiscussion = false;
            })

            // Update Discussion
            .addCase(updateDiscussion.pending, (state) => {
                state.loadingDiscussion = true;
            })
            .addCase(updateDiscussion.fulfilled, (state, action) => {
                state.loadingDiscussion = false;
                state.updateDiscussionResult = action.payload;
            })
            .addCase(updateDiscussion.rejected, (state) => {
                state.loadingDiscussion = false;
            })

            // Delete Discussion
            .addCase(deleteDiscussion.pending, (state) => {
                state.loadingDelete = true;
            })
            .addCase(deleteDiscussion.fulfilled, (state) => {
                state.loadingDelete = false;
            })
            .addCase(deleteDiscussion.rejected, (state) => {
                state.loadingDelete = false;
            })

            // Report Discussion
            .addCase(reportDiscussion.pending, (state) => {
                state.loadingDiscussion = true;
            })
            .addCase(reportDiscussion.fulfilled, (state) => {
                state.loadingDiscussion = false;
            })
            .addCase(reportDiscussion.rejected, (state) => {
                state.loadingDiscussion = false;
            })

            // Reply Discussion
            .addCase(replyDiscussion.pending, (state) => {
                state.loadingDiscussion = true;
            })
            .addCase(replyDiscussion.fulfilled, (state) => {
                state.loadingDiscussion = false;
            })
            .addCase(replyDiscussion.rejected, (state) => {
                state.loadingDiscussion = false;
            })

            // Reply Discussion Comment
            .addCase(commentDiscussion.pending, (state) => {
                state.loadingDiscussion = true;
            })
            .addCase(commentDiscussion.fulfilled, (state) => {
                state.loadingDiscussion = false;
            })
            .addCase(commentDiscussion.rejected, (state) => {
                state.loadingDiscussion = false;
            })

            // Update Discussion Reply
            .addCase(updateReplyDiscussion.pending, (state) => {
                state.loadingDiscussion = true;
            })
            .addCase(updateReplyDiscussion.fulfilled, (state) => {
                state.loadingDiscussion = false;
            })
            .addCase(updateReplyDiscussion.rejected, (state) => {
                state.loadingDiscussion = false;
            })

            // Update Discussion Comment
            .addCase(updateCommentDiscussion.pending, (state) => {
                state.loadingDiscussion = true;
            })
            .addCase(updateCommentDiscussion.fulfilled, (state) => {
                state.loadingDiscussion = false;
            })
            .addCase(updateCommentDiscussion.rejected, (state) => {
                state.loadingDiscussion = false;
            })

            // Delete Discussion Reply
            .addCase(deleteDiscussionReply.pending, (state) => {
                state.loadingDelete = true;
            })
            .addCase(deleteDiscussionReply.fulfilled, (state) => {
                state.loadingDelete = false;
            })
            .addCase(deleteDiscussionReply.rejected, (state) => {
                state.loadingDelete = false;
            })

            // Delete Discussion Comment
            .addCase(deleteDiscussionComment.pending, (state) => {
                state.loadingDelete = true;
            })
            .addCase(deleteDiscussionComment.fulfilled, (state) => {
                state.loadingDelete = false;
            })
            .addCase(deleteDiscussionComment.rejected, (state) => {
                state.loadingDelete = false;
            })

            // Mark as Best Reply
            .addCase(markAsBestReply.pending, (state) => {
                state.loadingMark = true;
            })
            .addCase(markAsBestReply.fulfilled, (state) => {
                state.loadingMark = false;
            })
            .addCase(markAsBestReply.rejected, (state) => {
                state.loadingMark = false;
            });
    }
});

export const { resetDiscussionState } = discussionSlice.actions;
export default discussionSlice.reducer;
