import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apps } from "../../utils/api";

// Get Detail Product
export const getMentorCourseProduct = createAsyncThunk('mentor/getMentorCourseProduct', async (id) => {
    try {
        const response = await apps.get(`/api/course/detail/${id}`);
        return response.data.product;
    } catch (error) {
        throw new Error(error);
    }
});

// Update Mentor Course Product
export const updateMentorCourseProduct = createAsyncThunk('mentor/updateMentorCourseProduct', async ({ id, payload }) => {
    try {
        let data = {
            "product_id": id,
            "normal_price": payload.normalPrice,
            "retail_price": payload.retailPrice
        };

        const response = await apps.post(`/api/course/updatePricing`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

// Get Detail Product
export const getMentorRoadmapProduct = createAsyncThunk('mentor/getMentorRoadmapProduct', async (id) => {
    try {
        const response = await apps.get(`/api/course/detailRoadmap/${id}`);
        return response.data.product;
    } catch (error) {
        throw new Error(error);
    }
});

// Update Mentor Roadmap Product
export const updateMentorRoadmapProduct = createAsyncThunk('mentor/updateMentorRoadmapProduct', async (data) => {
    const payload = JSON.stringify(data)
    try {
        const response = await apps.put(`/api/course/updateRoadmapPricing`, payload);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const mentorProductSlice = createSlice({
    name: 'mentorProduct',
    initialState: {
        mentorCourseProduct: [],
        mentorRoadmapProduct: [],
        loadingMentorProduct: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get Detail Product
            .addCase(getMentorCourseProduct.pending, (state) => {
                state.loadingMentorProduct = true;
            })
            .addCase(getMentorCourseProduct.fulfilled, (state, action) => {
                state.loadingMentorProduct = false;
                state.mentorCourseProduct = action.payload;
            })
            .addCase(getMentorCourseProduct.rejected, (state, action) => {
                state.loadingMentorProduct = false;
                state.error = action.error?.message;
            })

            // Update Course Product
            .addCase(updateMentorCourseProduct.pending, (state) => {
                state.loadingMentorProduct = true;
            })
            .addCase(updateMentorCourseProduct.fulfilled, (state) => {
                state.loadingMentorProduct = false;
            })
            .addCase(updateMentorCourseProduct.rejected, (state, action) => {
                state.loadingMentorProduct = false;
                state.error = action.error?.message;
            })

            // Get Detail Course Product
            .addCase(getMentorRoadmapProduct.pending, (state) => {
                state.loadingMentorProduct = true;
            })
            .addCase(getMentorRoadmapProduct.fulfilled, (state, action) => {
                state.loadingMentorProduct = false;
                state.mentorRoadmapProduct = action.payload;
            })
            .addCase(getMentorRoadmapProduct.rejected, (state, action) => {
                state.loadingMentorProduct = false;
                state.error = action.error?.message;
            })

            // Update Course Product
            .addCase(updateMentorRoadmapProduct.pending, (state) => {
                state.loadingMentorProduct = true;
            })
            .addCase(updateMentorRoadmapProduct.fulfilled, (state) => {
                state.loadingMentorProduct = false;
            })
            .addCase(updateMentorRoadmapProduct.rejected, (state, action) => {
                state.loadingMentorProduct = false;
                state.error = action.error?.message;
            });
    }
});

export default mentorProductSlice.reducer;
