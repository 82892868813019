import axios from 'axios';
import Cookies from 'js-cookie';
import store from '../app/store';
import { logout } from '../features/auth/authSlice';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const publicApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const apps = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

const publicApps = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

// Interceptor untuk menyertakan token di setiap request
const authInterceptor = config => {
    const token = Cookies.get('jwt');
    if (token) {
        config.headers.Authorization = token;
    }
    return config;
};

// Tambahkan interceptor request ke instance yang butuh autentikasi
api.interceptors.request.use(authInterceptor);
apps.interceptors.request.use(authInterceptor);

// Fungsi untuk refresh token agar tidak duplikat
let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = (newToken) => {
    refreshSubscribers.forEach(callback => callback(newToken));
    refreshSubscribers = [];
};

const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
};

// Interceptor untuk menangani 401 dan refresh token
const responseInterceptor = async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        console.warn('Token expired, attempting to refresh...');
        originalRequest._retry = true;

        if (isRefreshing) {
            return new Promise((resolve) => {
                addRefreshSubscriber((newToken) => {
                    originalRequest.headers.Authorization = newToken;
                    resolve(axios(originalRequest));
                });
            });
        }

        isRefreshing = true;

        try {
            const refreshToken = Cookies.get('refreshToken');
            if (!refreshToken) throw new Error('No refresh token found');

            const data = new FormData();
            data.append('refresh_token', refreshToken);

            const refreshResponse = await publicApps.post('/api/user/auth/refresh_access_token', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            const newAccessToken = refreshResponse.data.jwt;
            console.log('Token refreshed successfully:', newAccessToken);

            Cookies.set('jwt', newAccessToken, { path: '', secure: true, sameSite: 'Lax' });

            isRefreshing = false;
            onRefreshed(newAccessToken);

            originalRequest.headers.Authorization = newAccessToken;
            return axios(originalRequest);
        } catch (err) {
            console.error('Failed to refresh access token:', err);
            isRefreshing = false;

            store.dispatch(logout(err.response.data.message));
            return Promise.reject(err);
        }
    }
    return Promise.reject(error);
};

// Tambahkan interceptor response ke instance API
api.interceptors.response.use(response => response, responseInterceptor);
apps.interceptors.response.use(response => response, responseInterceptor);

export { api, publicApi, apps, publicApps };
