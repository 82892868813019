import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import ReactGA from 'react-ga';
import ProtectedRoute from "./components/global/ProtectedRoute";
import NotFound from "./pages/NofFound/NotFound";
import { unprotectedRoutes, protectedRoutes } from "./routes";
import { userRole, userToken } from "./utils/config";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const App = () => {
  const MySwal = withReactContent(Swal);
  const isLoggedOut = useSelector(state => state.auth.isLoggedOut);
  const errorMessage = useSelector(state => state.auth.errorMessage);

  // Tracking Google Analitycs
  const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // Redirect to login
  useEffect(() => {
    if (isLoggedOut) {
      MySwal.fire({
        title: 'Sesi Berakhir',
        text: errorMessage,
        icon: 'warning',
        confirmButtonColor: '#14a7a0',
        willClose: () => {
          window.location.href = `${process.env.REACT_APP_URL}/logout`;
        }
      });
    }
  }, [isLoggedOut, MySwal, errorMessage]);

  return (
    <Router>
      <Routes>
        {/* Unprotected Routes */}
        <Route path="/" element={<Navigate to="/learn" />} />

        {unprotectedRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={route.element}
            />
          );
        })}

        {/* Protected Routes */}
        {protectedRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoute path={route.path} auth={userToken} role={userRole}>
                  {route.element}
                </ProtectedRoute>
              }
            />
          );
        })}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
