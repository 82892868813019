import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicApps } from "../../utils/api";

export const getBanners = createAsyncThunk('banner/getBanners', async () => {
    try {
        const response = await publicApps.get(`/api/entry/index/slider`);
        return response.data.results;
    } catch (error) {
        throw new Error(error);
    }
});

const bannerSlice = createSlice({
    name: 'banner',
    initialState: {
        banners: [],
        loadingBanner: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBanners.pending, (state) => {
                state.loadingBanner = true;
            })
            .addCase(getBanners.fulfilled, (state, action) => {
                state.loadingBanner = false;
                state.banners = action.payload;
            })
            .addCase(getBanners.rejected, (state) => {
                state.loadingBanner = false;
            });
    }
});

export default bannerSlice.reducer;
