import { faCheckCircle, faChevronLeft, faChevronRight, faComputer, faQuestionCircle, faSun, faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './SidebarCollapse.module.scss';

// Sweetalert
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useSelector } from 'react-redux';
import { faMoon } from '@fortawesome/free-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { setTheme, systemTheme } from '../../../features/theme/themeSlice';
import { api, apps } from '../../../utils/api';

const SidebarCollapse = ({ isOpen, onClickToggle, courseSlug, examData, lessons, markedLessons, courseIsComplete, activeTopicId, enableCertificate, onClickItem }) => {
    const navigate = useNavigate()
    const location = useLocation();
    const { slug } = useParams();
    const { course } = useSelector((state) => state.courses);
    const { theme } = useSelector((state) => (state.theme));

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    let newMarkedLessons = [];

    markedLessons.forEach(markedLesson => {
        newMarkedLessons.push(markedLesson.lesson_id);
    });

    const handleCompleteCourse = async () => {
        let data = {
            'course_id': course?.course?.id,
        };

        const response = await api.post(`/course/complete`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        })

        if (response.data.status === "success") {
            navigate(`/learn/courses/${slug}/claim-certificate`);
        } else {
            MySwal.fire({
                icon: 'error',
                title: <strong>Gagal</strong>,
                text: 'Kamu harus menyelesaikan kelas ini terlebih dahulu',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#14a7a0',
            });
        };
    };

    const handleFinishCourse = () => {
        if (courseIsComplete.status !== 'success') {
            MySwal.fire({
                title: <strong>Apa kamu yakin sudah paham semua materinya?</strong>,
                text: 'Klik tombol selesai untuk menyelesaikan kelas ini',
                icon: 'question',
                confirmButtonText: 'Selesai',
                confirmButtonColor: '#14a7a0',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                allowOutsideClick: false,
                allowEscapeKey: false,
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    handleCompleteCourse();
                };
            });
        } else {
            MySwal.fire({
                title: <strong>Maaf</strong>,
                text: 'Kamu sudah menyelesaikan kelas ini. Selanjutnya silahkan klaim sertifikatnya ya.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#14a7a0',
            });
        };
    };

    const handleClaimCertificate = async () => {
        try {
            let data = {
                "object_id": course?.course?.id,
                "object_type": "course"
            };

            const response = await apps.post(`/api/certificate/claim`, data, {
                headers: {
                    "Content-Type": "application/json",
                }
            })

            if (response.data.status === "success") {
                navigate(`/learn/courses/${courseSlug}/claim-certificate`)
            } else {
                MySwal.fire({
                    title: <strong>Oops!</strong>,
                    text: response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
            }
        } catch (error) {
            throw new Error(error.message);
        };
    };

    return (
        <div className={isOpen ? `${styles.sidebar_collapse} ${styles.open} ${theme === 'dark' && 'bg-soft-dark border-dark'}` : `${styles.sidebar_collapse} ${theme === 'dark' && 'bg-soft-dark border-dark'}`}>
            <div className="container mt-3">
                <div className="row justify-content-center align-items-center">
                    <div className="col my-auto">
                        <a className="navbar-brand" href={process.env.REACT_APP_URL}>
                            <img height={35} width={'auto'} className="d-none d-md-block" src={theme === 'dark' ? '/assets/img/codepolitan-logo-white.png' : '/assets/img/codepolitan-logo.png'} alt="Codepolitan" />
                            <img height={35} width={'auto'} className="d-md-none" src="/assets/img/codepolitan-logo-mobile.png" alt="Codepolitan" />
                        </a>
                    </div>
                    <div className="col-auto my-auto">
                        <div className="dropdown">
                            <button className="btn btn-outline-primary dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {theme === 'light' ? (<FontAwesomeIcon fixedWidth icon={faSun} />) : null}
                                {theme === 'dark' ? (<FontAwesomeIcon fixedWidth icon={faMoon} />) : null}
                                {!theme ? (<FontAwesomeIcon fixedWidth icon={faSun} />) : null}
                            </button>
                            <ul className="dropdown-menu">
                                <li><button onClick={() => dispatch(setTheme('light'))} type="button" className="dropdown-item text-muted"><FontAwesomeIcon fixedWidth icon={faSun} /> Light</button></li>
                                <li><button onClick={() => dispatch(setTheme('dark'))} type="button" className="dropdown-item text-muted"><FontAwesomeIcon fixedWidth icon={faMoon} /> Dark</button></li>
                                <li><button onClick={() => dispatch(systemTheme())} type="button" className="dropdown-item text-muted"><FontAwesomeIcon fixedWidth icon={faComputer} /> System</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="overflow-auto my-4" style={{ maxHeight: '85vh' }}>
                    <p className={`${theme === 'dark' ? 'text-white' : 'text-muted'} px-3 fw-bolder text-uppercase mb-3`}>Table of Contents</p>
                    <div className="accordion accordion-flush" id="accordionSidebar">
                        {lessons?.map((lesson, index) => {
                            return (
                                <div className="accordion-item bg-transparent" key={index}>
                                    <h2 className="accordion-header" id={'flush-heading' + lesson.topic_slug}>
                                        <button className={activeTopicId === lesson.id ? `accordion-button shadow-none bg-transparent ${theme === 'dark' ? 'text-white' : 'text-muted'} px-3` : `accordion-button shadow-none bg-transparent collapsed ${theme === 'dark' ? 'text-white' : 'text-muted'} px-3`} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + lesson.id} aria-expanded={activeTopicId === lesson.id ? "true" : "false"} aria-controls={"collapse" + lesson.id}>
                                            {lesson.topic_title}
                                        </button>
                                    </h2>
                                    <div id={"collapse" + lesson.id} className={activeTopicId === lesson.id ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={'flush-heading' + lesson.topic_slug}>
                                        <div className="accordion-body p-1">
                                            <ul className="timeline">
                                                {lesson.contents?.map((content, index) => {
                                                    if (content.lesson_title === document.title) {
                                                        document.querySelector('.active').scrollIntoView({ behavior: 'smooth' });
                                                    }

                                                    const title = content?.lesson_title?.split(" ") || [];

                                                    return (
                                                        <li className={location.pathname === `/learn/courses/${courseSlug}/lessons/${content.id}` ? "mx-auto active" : "mx-auto"} key={index} onClick={onClickItem}>
                                                            <Link className="link" to={`/learn/courses/${courseSlug}/lessons/${content.id}`}>
                                                                <div className={location.pathname === `/learn/courses/${courseSlug}/lessons/${content.id}` ? `card rounded-2 bg-primary text-white border-0` : `card rounded-2 text-muted ${theme === 'dark' ? 'bg-dark' : 'bg-light'} border-0`}>
                                                                    <div className={`card-body ${theme === 'dark' ? 'text-white' : null}`}>
                                                                        <div className="d-flex">
                                                                            <FontAwesomeIcon fixedWidth icon={['Quiz', 'Kuis'].includes(title?.[0]) ? faQuestionCircle : faVideoCamera} />
                                                                            <h6 className="ms-2">{content.lesson_title}</h6>
                                                                        </div>
                                                                        <div className="d-flex justify-content-between">
                                                                            <div className="col-auto float-end">
                                                                                <span className="small me-2">{content.duration || '5:00'} menit</span>
                                                                            </div>
                                                                            {newMarkedLessons.includes(content.id) && (
                                                                                <div className="col-auto">
                                                                                    <span className="badge bg-primary text-white rounded-pill">
                                                                                        <FontAwesomeIcon aria-hidden className="my-auto" fixedWidth icon={faCheckCircle} />
                                                                                        <span className="ms-1">Selesai</span>
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <hr className="text-secondary border-1 opacity-25 mt-auto mb-3" />
                    {examData && (
                        <>
                            <Link className={`link ${theme === 'dark' ? 'text-white' : 'text-muted'} px-3 m-0`} to={`/learn/courses/${courseSlug}/exam`}>Exam</Link>
                            <hr className="text-secondary border-1 opacity-25 mb-2" />
                        </>
                    )}
                    {enableCertificate !== 0 ? (
                        <>
                            <button type="button" onClick={handleFinishCourse} className={`btn shadow-none link ${theme === 'dark' ? 'text-white' : 'text-muted'} px-3 mb-2`}>Finish Course</button>
                            <hr className="text-secondary border-1 opacity-25 mt-auto mb-2" />
                            <div className="d-grid">
                                {courseIsComplete?.status === "success" ? (
                                    <Link className={`btn btn-outline-secondary shadow-none m-0`} to={`/learn/courses/${courseSlug}/claim-certificate`}>Claim Certificate</Link>
                                ) : (
                                    <button type="button" onClick={handleClaimCertificate} className={`btn btn-outline-secondary shadow-none m-0`}>Claim Certificate</button>
                                )}
                            </div>
                        </>
                    ) : null}
                </div>
            </div>

            <button onClick={onClickToggle} className={`${styles.sidebar_toggle} ${theme === 'dark' && 'bg-soft-dark border-dark'}`}>
                <FontAwesomeIcon className={theme === 'dark' ? 'text-primary' : 'text-dark'} icon={isOpen ? faChevronLeft : faChevronRight} />
            </button>
        </div>
    );
};
export default SidebarCollapse;
