import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apps } from "../../utils/api";

export const getOccupations = createAsyncThunk('user/getOccupations', async () => {
    try {
        const response = await apps.get(`/api/entry/index/user_occupation`);
        return response.data.results;
    } catch (error) {
        throw new Error(error);
    }
});

export const getOccupation = createAsyncThunk('user/getOccupation', async (id, { dispatch }) => {
    try {
        const response = await apps.get(`/api/entry/detail/user_occupation/${id}`);
        dispatch(getOccupations());
        return response.data.result;
    } catch (error) {
        throw new Error(error);
    }
});

export const postOccupation = createAsyncThunk('user/postOccupation', async (payload, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('title', payload.title);
        data.append('company', payload.company);
        data.append('description', payload.description);
        data.append('date_start', payload.date_start);
        data.append('date_end', payload.date_end ? payload.date_end : undefined);
        data.append('letter', payload.letter);

        const response = await apps.post(`/api/entry/insert/user_occupation`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch(getOccupations());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const updateOccupation = createAsyncThunk('user/updateOccupation', async (payload, { dispatch }) => {
    try {
        let data = new FormData();
        data.append('title', payload.title);
        data.append('company', payload.company);
        data.append('description', payload.description);
        data.append('date_start', payload.date_start);
        data.append('date_end', payload.date_end);
        data.append('letter', payload.letter);

        const response = await apps.post(`/api/entry/update/user_occupation/${payload.id}`, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        dispatch(getOccupations());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

export const deleteOccupation = createAsyncThunk('user/deleteOccupation', async (id, { dispatch }) => {
    try {
        const response = await apps.delete(`/api/entry/delete/user_occupation/${id}`, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        dispatch(getOccupations());
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    occupations: [],
    occupation: {},
    loadingOccupation: false
};

const occupationSlice = createSlice({
    name: 'occupation',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get Portfolios Array
            .addCase(getOccupations.pending, (state) => {
                state.loadingOccupation = true;
            })
            .addCase(getOccupations.fulfilled, (state, action) => {
                state.loadingOccupation = false;
                state.occupations = action.payload;
            })
            .addCase(getOccupations.rejected, (state) => {
                state.loadingOccupation = false;
            })

            // Get Single Portfolio
            .addCase(getOccupation.pending, (state) => {
                state.loadingOccupation = true;
            })
            .addCase(getOccupation.fulfilled, (state, action) => {
                state.loadingOccupation = false;
                state.occupation = action.payload;
            })
            .addCase(getOccupation.rejected, (state) => {
                state.loadingOccupation = false;
            })

            // Post Portfolio
            .addCase(postOccupation.pending, (state) => {
                state.loadingOccupation = true;
            })
            .addCase(postOccupation.fulfilled, (state) => {
                state.loadingOccupation = false;
            })
            .addCase(postOccupation.rejected, (state) => {
                state.loadingOccupation = false;
            })

            // Update Portfolio
            .addCase(updateOccupation.pending, (state) => {
                state.loadingOccupation = true;
            })
            .addCase(updateOccupation.fulfilled, (state) => {
                state.loadingOccupation = false;
            })
            .addCase(updateOccupation.rejected, (state) => {
                state.loadingOccupation = false;
            })

            // Delete Portfolio
            .addCase(deleteOccupation.pending, (state) => {
                state.loadingOccupation = true;
            })
            .addCase(deleteOccupation.fulfilled, (state) => {
                state.loadingOccupation = false;
            })
            .addCase(deleteOccupation.rejected, (state) => {
                state.loadingOccupation = false;
            });
    }
});

export default occupationSlice.reducer;
